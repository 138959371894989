import { MainMenuItem } from "@model/app-state";
import { BlogInfo } from "@model/blog-info";
import { MainPage } from "@model/main-page";
import { S3ObservableFactory } from "aws/aws-observable";
import moment from "moment";
import { Observable, scheduled, asyncScheduler, switchMap, filter, from, map, forkJoin, catchError, mergeMap, reduce } from "rxjs";
import { encodeMetaInfoPath } from "utils/endoders";
import { createS3Client } from "utils/s3-client.factory";
import { getSignedItemPath } from "utils/use-signed-url.hook";

export function getMainPageContent(bloggerMode: boolean | undefined, bucket: string | undefined, page?: string, websiteDomain?: string): Observable<MainPage> {
  return !!bloggerMode
    ? (!!bucket ? loadMainPageToReview(bucket, page) : scheduled([], asyncScheduler))
    : loadWebsiteMainPage(websiteDomain ?? 'domain-isnot-defined', page);
}

function blogsToMenuItems(blogs: BlogInfo[]): MainMenuItem[] {
  return blogs.map(b => ({ title: b.title, path: b.fullUrl } as MainMenuItem));
}

export function getMainPage(bloggerMode: boolean | undefined, bucket: string | undefined, mainMenu?: MainMenuItem[], page?: string, websiteDomain?: string) {
  return getMainPageContent(bloggerMode, bucket, page, websiteDomain).pipe(
    switchMap(x => {
      return !!mainMenu || !page
      ? scheduled([{ ...x, mainMenu: mainMenu ?? blogsToMenuItems(x.blogs) }], asyncScheduler)
        : getMainPageContent(bloggerMode, bucket, undefined, websiteDomain).pipe(
          map(({ blogs }) => blogsToMenuItems(blogs)),
          map(mm => ({ ...x, mainMenu: mm })));
  }))
}

function loadMainPageToReview(contentBucket: string, selectedFolder?: string, blogsLocation?: string): Observable<MainPage> {
  const contentBucket$ = scheduled([contentBucket], asyncScheduler);

  return contentBucket$.pipe(
    switchMap(contentBucket => {
      const s3ObservableFactory = new S3ObservableFactory();
      const getObject = s3ObservableFactory.getObject();
      const enc = new TextDecoder();
      //   const posts = [
      //     {
      //         title: "test",
      //         urlName: "/architecture/test",
      //         description: "test desr",
      //         tags: ["test tag"],
      //         date: moment(),
      //         postUrl: "/arch/test",
      //         postImageUrl: "/static/images/arch.png"
      //     }
      // ] as PostInfo[];
      // return of(posts);
      const mainPage$ = getObject({
        Bucket: `${contentBucket!}`,
        Key: !!selectedFolder ? `${selectedFolder}/main-page.json` : 'main-page.json'
      });
      return mainPage$.pipe(
        filter(x => !x.error),
        switchMap(({ data }) => !!data?.Body ? from(data?.Body?.transformToByteArray()).pipe(map( x => x as BufferSource)) : scheduled([], asyncScheduler)),
        map(data => {
          const content = enc.decode(data);
          const mainPage: MainPage = JSON.parse(content);
          return { ...mainPage, posts: fixWebPageItem(mainPage.posts, false), blogs: fixWebPageItem(mainPage.blogs, false) };
        }),
        switchMap(({ posts, blogs }) => forkJoin({ posts: enrichSignedImageUrls(posts, contentBucket, blogsLocation), blogs: enrichSignedImageUrls(blogs, contentBucket, blogsLocation) }))
      );
    })
  )
}

function loadWebsiteMainPage(websiteDomain: string, selectedFolder?: string): Observable<MainPage> {
  const folder = !!selectedFolder ? `${selectedFolder}/` : '';
  const urlHost = import.meta.env.SSR ? `https://${websiteDomain}` : '';
  const mainPageUrl = `${urlHost}/content/${folder}main-page.json`;

  if (import.meta.env.SSR) {
    console.log('Main Page Url: ', mainPageUrl);
  }
  return scheduled(fetch(mainPageUrl), asyncScheduler).pipe(
    switchMap(resp => {
      return scheduled(resp.headers.get('content-type') === 'application/json' ? resp.json() : [], asyncScheduler)
        .pipe(
          map((mainPage: MainPage) => ({...mainPage, posts: fixWebPageItem(mainPage.posts), blogs: fixWebPageItem(mainPage.blogs)}))
        );
      }),
    catchError(_ => scheduled([{ blogs: [], posts: [] } as MainPage], asyncScheduler))
  )
}

function fixWebPageItem<T extends BlogInfo>(items: T[], fixUrls: boolean = true): T[] {
  return items.map(x => ({
    ...x, date: moment(x.date), ...(fixUrls
      ? {
        titleImage: `/content/${encodeMetaInfoPath(x.titleImage)}`,
      }
      : { }),
    ...{
        fullUrl: `/${x.fullUrl}` // no need to encode as it should be entered already encoded
      }
  }));
}

function enrichSignedImageUrls<T extends BlogInfo>(items: T[], bucket: string, blogsLocation?: string): Observable<T[]> {
  const s3Client = createS3Client()!;
  const getImageUrl = (itemPath: string) => {
    return getSignedItemPath(itemPath, bucket, s3Client, blogsLocation);
  }
  return scheduled(items, asyncScheduler).pipe(
    mergeMap(x => scheduled(getImageUrl(x.titleImage).then(url => ({ ...x, titleImage: url } as T)), asyncScheduler)),
    reduce((acc, item) => [...acc, item], [] as T[])
  );
}
