import { selectBlogger, selectUser } from './redux/modules/user/selectors';
import { useAppSelector, useAppDispatch } from './redux/hooks';
import { signin, signout } from 'redux/modules/user/userSlice';
import { selectDrawer, selectFullScreen, selectMainMenu } from 'redux/modules/app/selectors';
import { closeDrawer, openDrawer } from 'redux/modules/app/uiStateSlice';
import App from 'App';
import { useCallback } from 'react';

function ConnectedApp() {
  const user = useAppSelector( selectUser );
  const drawer = useAppSelector(selectDrawer);
  const fullScreen = useAppSelector(selectFullScreen);
  const blog = useAppSelector(selectBlogger);
  const mainMenu = useAppSelector(selectMainMenu);

  const dispatch = useAppDispatch();

  const signInCallback = useCallback(() => dispatch(signin()), [signin, dispatch]);
  const signOutCallback = useCallback(() => dispatch(signout()), [signout, dispatch]);
  const openDrawerCallback = useCallback(() => dispatch(openDrawer()), [openDrawer, dispatch]);
  const closeDrawerCallback = useCallback(() => dispatch(closeDrawer()), [closeDrawer, dispatch]);

  return (
    <App user={user} mainMenu={mainMenu} signin={signInCallback} signout={signOutCallback} 
      drawer={drawer} openDrawer={openDrawerCallback} closeDrawer={closeDrawerCallback}
      fullScreen={fullScreen}
      mode={blog.mode}
    />
  );
}

export default ConnectedApp;
