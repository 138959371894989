import { BloggerState } from "@model/blogger-state";
import { Typography } from "@mui/material";
import { S3ObservableFactory } from "aws/aws-observable";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectBlogger } from "redux/modules/user/selectors";
import { asyncScheduler, firstValueFrom, from, map, scheduled, switchMap } from "rxjs";
import { MainContent } from "./main-content";
import { fullScreen } from "redux/modules/app/uiStateSlice";
import { selectFullScreen } from "redux/modules/app/selectors";
import { useDebounceEffect } from "utils/debounce-effect.hook";

export type ReviewerProps<TContent> = {
  blogger: BloggerState,
  data: TContent | null,
  itemPath: string | undefined,
  fetchItem: (itemPath: string, bucket: string) => Promise<TContent|null>, 
  refreshItem: () => Promise<TContent|null>, 
  fullScreen: boolean,
  setFullScreen: (fullScreen: boolean) => void,
  isNewFile?: boolean
}

export type ConnectedReviewerProps<TContent> = {
  autoloading?: boolean,
  encoder?: (data: BufferSource | undefined) => TContent
  children: (props: ReviewerProps<TContent>) => ReactElement<any, any>,
  editorBucket?: boolean
}

export function ConnectedReviewer<TContent = string>({children, encoder, autoloading, editorBucket}: ConnectedReviewerProps<TContent>) {
  const blogger = useAppSelector(selectBlogger);
  const fullScreenState = useAppSelector(selectFullScreen);

  const dispatch = useAppDispatch();
  const [data, setData] = useState<TContent|null>(null);

  const params = useParams();
  const itemId = useMemo(() => 
    params['*'], [params]);
  const itemName = useMemo(() => {
    const parts = itemId?.split('/');
    setData(null);
    return !!parts ? parts[parts.length - 1] : null;
  }, [itemId]);
  const itemFolder = useMemo(() => itemId?.replace(itemName!, ''), [itemId, itemName]);
  const bucket = useMemo(() => editorBucket === true ? blogger.editorBucket : blogger.reviewerBucket, [blogger, editorBucket]);
  const factory = useMemo(() => !!bucket ? new S3ObservableFactory() : null, [bucket]);
  const currentEncoder = useMemo(() => !!encoder ? encoder 
    : (bufferData: BufferSource | undefined) => {
      const enc = new TextDecoder();
      const content = enc.decode(bufferData as BufferSource);
      return typeof content == typeof '' ? content as unknown as TContent : null;
    }, [encoder]);

  const [fetchUserItemFlag, setFetchUserItemFlag] = useState(false);

  const fetchItem = useCallback(async (itemPath: string, bucket: string) => {
    const getObject = factory!.getObject();
    const getObject$ = getObject({
      Bucket: bucket,
      Key: `${blogger.blogsLocation}${itemPath}`
    }).pipe(
      switchMap(({ data, error }) => {
        return !!data?.Body ? from(data.Body.transformToByteArray()).pipe(map(body => ({ data: body as BufferSource, error }))) : scheduled([{ data: undefined, error }], asyncScheduler);
      }),
      map(({ data }) => {
        const content = currentEncoder(data!)
        return content;
      })
    );
    return await firstValueFrom(getObject$);
  }, [blogger?.blogsLocation, factory]);

  const fetchUserItem = useCallback(async () => {
    if (!!itemId && !!bucket) {
      const content = await fetchItem(itemId!, bucket!)
      setData(content);
      return content;
    } else {
      setFetchUserItemFlag(true);
    }
    return null;
  }, [itemId, bucket, fetchItem, currentEncoder]);

  useDebounceEffect(() => {
    if ((fetchUserItemFlag || autoloading !== false) && !data) {
      fetchUserItem();
    }
  }, [itemId, fetchUserItemFlag, autoloading, data, fetchUserItem]);

  const setFullScreen = useCallback((fs: boolean) => dispatch(fullScreen(fs)), [dispatch]);

  return (
    <>
      <MainContent fullScreen={fullScreenState} fullWidth noMargin title={<span><Typography variant="h5">Reviewing: {itemName}</Typography>
        <Typography variant="subtitle2">at {itemFolder}</Typography> </span>}>
        {children({blogger, data, itemPath: itemId, fetchItem: fetchItem, refreshItem: fetchUserItem, fullScreen: fullScreenState, setFullScreen})}
      </MainContent>
    </>);
}