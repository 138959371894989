const allowedImages = ['.svg', '.png', '.jpg', '.jpeg'];

export class FileManager {
  static getEditorType(itemPath: string): string | null {
    if (itemPath.endsWith('.md')) {
      return 'posts';
    } else if (itemPath.endsWith('.drawio')) {
      return 'drawio';
    } else if (itemPath.endsWith('.puml')) {
      return 'puml';
    } else if (itemPath === 'meta.json') {
      return 'meta';
    } else if (itemPath.endsWith('main-page.json')) {
      return 'json';
    } else if (allowedImages.find(ext => itemPath.toLowerCase().endsWith(ext))) {
      return 'image';
    }
  return null;
  }

  static getImageExtension(editorType: string): string | null {
    switch (editorType) {
      case 'drawio':
      case 'puml':
        return '.svg';
    }

    return null;
  }

  static getFileExtension(editorType: string): string | null {
    switch (editorType) {
      case 'posts':
        return '.md';
      case 'drawio':
        return '.drawio';
      case 'puml':
        return '.puml';
      case 'meta':
          return '.meta.json';
        default:
        return null;
    }
  }

  static canDelete(filePath: string): boolean {
    return !filePath.endsWith("meta.json");
  }
}