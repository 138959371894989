import { Button, Stack, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import { MainContent } from "./main-content";
import { useEffect, useMemo } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ConnectedEditor } from "./editor.connected";
import PostEditor from "./post-editor";
import PlantUmlEditor from "./plantuml-editor";
import { DrawioEditor } from "./drawio-editor";
import { FileManager } from "utils/file-manager";
import { S3ObservableFactory } from "aws/aws-observable";
import { useAppSelector } from "redux/hooks";
import { selectBlogger } from "redux/modules/user/selectors";
import { firstValueFrom } from "rxjs";
import { BlogsEditorPath } from "app-constants";

export type ConnectedFileCreatorProps = {
}

export function ConnectedFileCreator({ }: ConnectedFileCreatorProps) {
  const navigate = useNavigate();
  const params = useParams();
  const [urlParams, setUrlParams] = useSearchParams();
  const editorType = useMemo(() => params['editorType'], [params]);
  const fileName = useMemo(() => urlParams.get('fileName'), [urlParams]);
  const blogger = useAppSelector( selectBlogger );
  const factory = useMemo(() => !!blogger.editorBucket ? new S3ObservableFactory() : null, [blogger.editorBucket]);

  useEffect(() => {
    if (!!fileName && !!factory) {
      const list = factory.listObjectsV2();
      const itemPrefix = `${params['*']}/${fileName}`;
      const fileList$ = list({
          Bucket: blogger.editorBucket!,
          Prefix: itemPrefix
      });
      firstValueFrom(fileList$).then(x => {
        if (!x.error && !!x.data?.Contents?.find(y => y.Key === itemPrefix)) {
          navigate(`/${BlogsEditorPath}/edit/${editorType}/${itemPrefix}`);
        }
      })
    }
  }, [fileName, factory]);
  
  return (<MainContent fullWidth noMargin title={ `Create a new File of type ${editorType}` }>
  {!fileName ? <Formik
       initialValues={{ fileName: fileName ?? '' }}
       validate={values => {
         const errors: any = {};
         if (!values.fileName) {
           errors.name = 'Required';
         } 
         return errors;
       }}
       onSubmit={(values) => {
        setUrlParams((prev) => ({...prev, fileName: values.fileName + FileManager.getFileExtension(editorType!)}))
       }}
     >
       {({
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         isValid,
         dirty
         /* and other goodies */
       }) => (
         <form onSubmit={handleSubmit}>
          <Stack spacing={2}>
            <TextField name="fileName" id="fileName" label="File Name" variant="standard"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.fileName}
              error={!!errors.fileName && !!touched.fileName}
              helperText={errors.fileName}
              />
            <Button type="submit" disabled={!dirty || !touched || !isValid || isSubmitting} variant="contained">Create</Button>
          </Stack>
         </form>
       )}
     </Formik>
  : <>
      <Typography variant="body2">
        File Name: {fileName}
      </Typography>
      <ConnectedEditor isNewFile={true} newFileName={fileName}>{props => {
        switch (editorType) {
          case 'posts':
            return <PostEditor {...props} />
          case 'drawio':
            return <DrawioEditor {...props} />
          case 'puml':
            return <PlantUmlEditor {...props} />
          default:
            return <></>
        }
      }}
      </ConnectedEditor>
  </>}
  </MainContent>);
}