import MoreIcon from '@mui/icons-material/MoreVert';
import { IconButton, Menu, MenuItem, PopoverOrigin } from '@mui/material';
import React, { useCallback, useId, useMemo, useState } from 'react';

export type MenuButtonProps = {
  children: React.ReactElement[],
  icon?: React.ReactElement,
  anchorOrigin?: PopoverOrigin,
  transformOrigin?: PopoverOrigin,
  id?: string
};

export function MenuButton({ children, icon, anchorOrigin, transformOrigin, id }: MenuButtonProps) {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const genId = useId();
  const handleOpenNavMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorElNav(event.currentTarget);
  }, []);
  
  const handleCloseNavMenu = useCallback(() => {
    setAnchorElNav(null);
  }, []);

  const menuOrigin = useMemo<PopoverOrigin>(() => anchorOrigin ?? {
    vertical: 'bottom',
    horizontal: 'left',
  } , [anchorOrigin]);
  const popupOrigin = useMemo<PopoverOrigin>(() => transformOrigin ?? {
    vertical: 'top',
    horizontal: 'left',
  }, [transformOrigin]);

  return (<><IconButton
    size="large"
    aria-label="show more"
    aria-haspopup="true"
    onClick={handleOpenNavMenu}
    color="inherit"
  >
    {icon || <MoreIcon />}
  </IconButton>
    <Menu
      id={id ?? genId}
      anchorEl={anchorElNav}
      anchorOrigin={menuOrigin}
      keepMounted
      transformOrigin={popupOrigin}
      open={Boolean(anchorElNav)}
      onClose={handleCloseNavMenu}
    >
      {children?.map(child => (child as any).type?.render?.name === 'Divider' ? child : <MenuItem key={child.key} onClick={handleCloseNavMenu}>{child}</MenuItem>)}
    </Menu>
  </>);
}