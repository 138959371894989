const CODE_VERIFIER_CHARSET =
	'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';


export type CodeVerifier = {
	value: string;
	method: 'S256';
	toCodeChallenge(): Promise<string>;
}

/**
 *
 * @param length Desired length of the code verifier.
 *
 * **NOTE:** According to the [RFC 7636](https://datatracker.ietf.org/doc/html/rfc7636#section-4.1)
 * A code verifier must be with a length >= 43 and <= 128.
 *
 * @returns An object that contains the generated `codeVerifier` and a method
 * `toCodeChallenge` to generate the code challenge from the `codeVerifier`
 * following the spec of [RFC 7636](https://datatracker.ietf.org/doc/html/rfc7636#section-4.2).
 */
export async function generateCodeVerifier(
	length: number,
): Promise<CodeVerifier> {
	const randomBytes = new Uint8Array(length);
  window.crypto.getRandomValues(randomBytes);

	let value = '';
	let codeChallenge: string | undefined;

	for (const byte of randomBytes) {
		value += CODE_VERIFIER_CHARSET.charAt(byte % CODE_VERIFIER_CHARSET.length);
	}

	return {
		value,
		method: 'S256',
		async toCodeChallenge() {
			if (codeChallenge) {
				return codeChallenge;
			}
			codeChallenge = await generateCodeChallenge(value);

			return codeChallenge!;
		},
	};
};

async function generateCodeChallenge(codeVerifier: string): Promise<string> {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const hash = await crypto.subtle.digest('SHA-256', data);

  const codeChallenge = removePaddingChar(
    base64urlEncode(new Uint8Array(hash)),
	);

	return codeChallenge;
}

function removePaddingChar(base64Encoded: string): string {
  return base64Encoded.replace(/=/g, '');
}

function base64urlEncode(buffer: Uint8Array): string {
  return btoa(String.fromCharCode.apply(null, Array.from(buffer)))
    .replace(/\+/g, '-') // Convert '+' to '-'
    .replace(/\//g, '_') // Convert '/' to '_'
    .replace(/=+$/, ''); // Remove trailing '='
}