import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import './App.scss';
import { Container, CssBaseline, ContainerProps } from '@mui/material';
import { DrawerState } from '@model/ui-state';
import { DrawerMain } from 'components/main-content';
import { drawerWidth } from 'global-settings';
import { ApplicationBar, ApplicationBarProps } from 'components/app-bar';
import { DocumentMeta } from 'components/document-meta';
import { MainMenuItem } from '@model/app-state';

export type AppParameters =  Omit<ApplicationBarProps, 'drawerIsOpen'> & {
  drawer?: DrawerState,
  mainMenu?: MainMenuItem[]
}

function App({ drawer, fullScreen, ...appBarProps } : AppParameters) {
  const open = drawer?.open === true && !fullScreen;
  const fullScreenProps = useMemo<ContainerProps>(() => (fullScreen ? { maxWidth: false, disableGutters: true } : {}), [fullScreen]);

  return (<>
    <CssBaseline />
      <DocumentMeta title='Full Stack Solution Architecture Blog' description='Full Stack Solution Architecture Blog' />
      <ApplicationBar {...appBarProps} fullScreen={fullScreen} drawerIsOpen={drawer?.open} />
      <DrawerMain open={open} drawerWidth={drawer?.width ?? drawerWidth} fullScreen={fullScreen}>
        <Container sx={{backgroundColor: 'white', paddingTop: fullScreen ? 0 : '48px', minHeight: '100vh'}} {...fullScreenProps} >
          <Outlet />
        </Container>
      </DrawerMain>
      </>
  );
}

export default App;
