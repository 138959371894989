import { AccountCircle } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

export type ProfileActionButtonProps = {
    userDisplayName: string,
    title?: string,
    signout: () => void
};

export function ProfileActionButton({ userDisplayName, signout }: ProfileActionButtonProps) {
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const handleOpenNavMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
      }, []);
    
    const handleCloseNavMenu = useCallback(() => {
        setAnchorElNav(null);
      }, []);
  const navigate = useNavigate();
  const navigateToProfile = useCallback(() => {
    handleCloseNavMenu();
    navigate('/user/profile');
  }, [navigate, handleCloseNavMenu]);


    return (<><IconButton
        size="large"
        edge="end"
        aria-label={userDisplayName}
        // aria-controls={menuId}
        aria-haspopup="true"
        // onClick={handleProfileMenuOpen}
        color="inherit"
        onClick={handleOpenNavMenu}
      >
        <AccountCircle />
    </IconButton>
    <Menu
        id="profile-menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        >
        <MenuItem key='profile' onClick={navigateToProfile}>
            <Typography variant="body1">
              Profile
            </Typography>
        </MenuItem>
        <MenuItem key="blogs" onClick={handleCloseNavMenu}>
            <IconButton size="large" aria-label='logout' onClick={signout} color="inherit">
                <LogoutIcon />
            </IconButton>
        </MenuItem>
    </Menu>
</>    
)
}