import { useCallback, useEffect, useMemo, useState } from "react";
import { ReviewerProps } from "./reviewer.connected";
import { Box, Button } from "@mui/material";
import 'highlight.js/styles/github-dark.css';

export type JsonViewerProps = ReviewerProps<string> & {
};

function JsonViewer({ blogger, data, itemPath, fetchItem }: JsonViewerProps) {
  const refreshItem = useCallback(async () => fetchItem(itemPath!, blogger.currentBucket!), [itemPath, blogger.currentBucket, fetchItem]);
  const json = useMemo(() => !!data
    ? JSON.stringify(JSON.parse(data), null, 4).replaceAll(' ', '&nbsp;')
    : '', [data]);

  const [htmlContent, setHtmlContent] = useState<string>('<span>loading...</span>');
  
  useEffect(() => {
      setHtmlContent(json.replaceAll('\\"', '&quot;').replaceAll(',', ', <br>'));
  }, [json]);

  return (<>
    <span>json viewer: {itemPath}</span> <Button onClick={() => refreshItem()} >refresh</Button>
    <Box>
      {!!data &&
            <Box>
            {!!htmlContent && <div dangerouslySetInnerHTML={{ __html: htmlContent }} />}
          </Box>
      }
    </Box>
  </>)
}

export default JsonViewer;