import { PostInfo } from "@model/post-info";
import { encodeMetaInfoPath } from "utils/endoders";

export type PostData = {
  content: string | null,
  meta: PostInfo | null
};

export async function fetchPostData(itemPath: string, host?: string): Promise<PostData | undefined> {
  const postPath = `${host ?? ''}/content${itemPath}.md`;
  const postMetaPath = `${postPath}.meta.json`;
  try {
    const postResp = await fetch(postPath);
    const metaResp = await fetch(postMetaPath);
    if (!metaResp.ok || !postResp.ok) return undefined;

    const meta = await metaResp.json() as PostInfo;
    const encodedTitleImage = encodeMetaInfoPath(meta.titleImage);
    meta.titleImage = `/content/${encodedTitleImage}`;
    return { content: await postResp.text(), meta } as PostData;
  }
  catch (err) {
    console.error(err);
  }
  return undefined;
}
