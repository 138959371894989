export const generateRandomString = (length: number) => {
	const STATE_CHARSET =
		'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let result = '';

	for (let i = 0; i < length; i++) {
		result += STATE_CHARSET.charAt(
			Math.floor(Math.random() * STATE_CHARSET.length),
		);
	}

	return result;
};

export const generateState = (): string => {
	return generateRandomString(32);
};
