
import { IconButton } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import { useMemo } from 'react';

export type UnauthenticatedActionButtonsProps = {
  signin: () => void
};

export function useUnauthenticatedActionButtons({ signin }: UnauthenticatedActionButtonsProps) {
  const children = useMemo(() => {
    const iconButton = <IconButton key="login" size="large" aria-label='login' onClick={signin} title='Login' color="inherit">
      <LoginIcon />
    </IconButton>;
    return [iconButton]
  }, [signin]);
  return children;
}