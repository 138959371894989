import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectBlogger } from 'redux/modules/user/selectors';
import { BlogCreator, CreateBlogFunc } from './blog-creator';
import { createPost } from "redux/modules/user/blogger/bloggerSlice";
import { useNavigate } from 'react-router-dom';
import { BlogsEditorPath } from 'app-constants';

function ConnectedPostCreator() {
  const blogger = useAppSelector( selectBlogger );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const createBlogCallback = useCallback<CreateBlogFunc>((blogInfo, parentId, image) => {
    dispatch(createPost({
      blogInfo,
      parent: parentId,
      image
    }));

    setTimeout(() => navigate(`/${BlogsEditorPath}/edit/posts/${parentId}/${blogInfo.urlName}.md`), 1000);
  }, [dispatch, navigate]);

  return (
    <BlogCreator isPost blogger={blogger} 
      createBlog={createBlogCallback}/>
  );
}

export default ConnectedPostCreator;
