import * as toolkit from "@reduxjs/toolkit";
import { Epic, combineEpics } from "redux-observable";
import { filter } from "rxjs/operators";
import { RootState } from "redux/store";


const detectLayoutTypeEpic: Epic<toolkit.PayloadAction<any>, toolkit.PayloadAction<any>, RootState> =
  (action$) => action$.pipe(
    filter(_ => false), // we need it to generate layout changing events
    // mergeWith(fromEvent(window, 'resize')),
    // startWith(undefined),
    // withLatestFrom(state$.pipe(map(s => selectCompactLayout(s)))),
    // map(([_evt, compactLayout]) => {
    //   const { innerWidth } = window;
    //   return innerWidth <= 600;
    // }),
    // distinct(),
    // map(x => setCompactLayout(x))
  );


export const uiEpic = combineEpics(detectLayoutTypeEpic);
