import { PayloadAction, Tuple, configureStore } from '@reduxjs/toolkit'
import { createEpicMiddleware } from 'redux-observable';
import user from './modules/user/userSlice';
import blogger from './modules/user/blogger/bloggerSlice';
import ui from './modules/app/uiStateSlice';
import app from './modules/app/appStateSlice';

import { rootEpic } from './rootEpic';

const storeType = configureStore({
  reducer: {
    user,
    blogger,
    ui,
    app
  }
});

export type RootState = ReturnType<typeof storeType.getState>;
export type AppDispatch = typeof storeType.dispatch;
export type StoreType = typeof storeType;

const epicMiddleware = createEpicMiddleware<PayloadAction<any>, PayloadAction<any>, any>();

let store: typeof storeType | undefined = undefined;

export const createStore = (preloadedState?: RootState) => {
  if (!!store) return store;

  const configuredStore = configureStore({
    reducer: {
      user,
      blogger,
      ui,
      app
    },
    preloadedState,
    middleware: () => new Tuple(epicMiddleware)
  });
  
  
  epicMiddleware.run(rootEpic);

  store = configuredStore;
  return configuredStore;
}
