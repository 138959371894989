import { GetObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { useCallback, useMemo } from "react";
import { createS3Client } from "./s3-client.factory";

export const isAbsoluteUrl = (url: string) => (!!url && url.length >= 7 && (url[4] === ':' || (url[5] === ':' && url[4] === 's')) && url.startsWith('http'));
const getS3Cmd = (key: string, bucket: string, blogsLocation?: string) => {
  return new GetObjectCommand({
    Bucket: bucket,
    Key: `${blogsLocation ?? ''}${key}`
  });
};


export const getSignedItemPath = async (itemPath: string, bucket: string, client: S3Client, blogsLocation?: string) => {
  const absoluteUrl = isAbsoluteUrl(itemPath);
  return !absoluteUrl && !!client
    ? getSignedUrl(client, getS3Cmd(itemPath, bucket, blogsLocation))
    : Promise.resolve(itemPath);
}
  
export function useSignedUrl(bucket?: string, blogsLocation?: string) {
  const client = useMemo(() => createS3Client(), [bucket]);
    
  const getItemSignedUrl = useCallback(async (itemPath: string) => {
    return !!itemPath && !!bucket && !!client
      ? getSignedItemPath(itemPath, bucket, client, blogsLocation)
      : Promise.resolve(undefined);
    },
    [client, bucket, blogsLocation]);

  return getItemSignedUrl;
}