import { Alert, Autocomplete, AutocompleteRenderInputParams, Button, Checkbox, Divider, FormControlLabel, Stack, TextField, Typography } from "@mui/material";
import { FormikErrors, FormikHelpers, useFormik } from "formik";
import { MainContent } from "./main-content";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { BloggerState } from "@model/blogger-state";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BlogInfo } from "@model/blog-info";
import { S3ObservableFactory } from "aws/aws-observable";
import { asyncScheduler, firstValueFrom, from, scheduled, switchMap } from "rxjs";
import PostCard from "./post-card";
import moment from "moment";
import InputFileUpload from "./input-file-upload";
import { PostInfo } from "@model/post-info";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { uuidv4 } from "utils/guid";
import { createS3Client } from "utils/s3-client.factory";

export type CreateBlogFunc = (blogInfo: BlogInfo, parentId?: string, image?: File) => void;

export type BlogCreatorProps = {
  isPost?: boolean,
  isEdit?: boolean,
  blogger: BloggerState,
  createBlog: CreateBlogFunc,
  post?: PostInfo
};

type BlogInfoItem = BlogInfo & { image: File, videoUrl: string };
type FormikValues = Partial<BlogInfoItem>;

// https://docs.aws.amazon.com/AmazonS3/latest/userguide/object-keys.html
const s3KeySafeCharactersErrorMessage = "Name should have at least 1 character. \nOnly the following characters are allowed (spaces are not allowed): 0-9 a-z A-Z !-_.*,'() ";
const s3KeySafeCharacters = /^([\w\!\-_\.*'\(\)%]+)$/;
const commonTags = [
  'Architecture', 'Software Development', 'IOT', 'ReactJs', '.Net', 'AWS', 'Serverless',
  'AWS CDK', 'Terraform', 'Terraform CDK', 'SSR', 'SSG'
]

export function BlogCreator({ blogger, createBlog, isPost, isEdit, post }: BlogCreatorProps) {
  const navigate = useNavigate();
  const params = useParams();
  const parentBlogId = useMemo(() => params['*'], [params]);
  const [tags, setTags] = useState(commonTags);
  const [initialTags, setInitialTags] = useState<string[]>([]);
  const factory = useMemo(() => !!blogger.editorBucket ? new S3ObservableFactory() : null, [blogger.editorBucket]);
  const [imageUrl, setImageUrl] = useState(post?.titleImage);
  const [urlParams] = useSearchParams();
  const fileName = useMemo<string | undefined>(() => {
    const fn = urlParams.get('fileName');
    return !fn ? undefined : fn;
  }, [urlParams]);

  useEffect(() => {
    if (!blogger.currentItem?.blog && blogger.currentItem?.saved === true) {
      navigate('..');
    }
  }, [blogger?.currentItem?.blog, blogger?.currentItem?.saved]);

  useEffect(() => {
    if (!!post) {
      if (!post.titleImage) return;
      
      const client = createS3Client();

      const cmd = new GetObjectCommand({
        Bucket: blogger.currentBucket,
        Key: post.titleImage
      });

      getSignedUrl(client, cmd).then(url => setImageUrl(url));
    } else {
      setImageUrl(undefined);
    }
  }, [blogger.currentBucket, post])

  useEffect(() => {
    if (!!parentBlogId && !!factory && isEdit !== true) {
      const parts = parentBlogId.split('/');
      const folders = parts.reduce((x, y) => x.length === 0 ? [`${y}/`] : [...x, `${x[x.length - 1]}${y}/`], [] as string[]);
      const getObject$ = factory.getObject();
      const enc = new TextDecoder();
      const metaFiles = folders.map(folder =>
        firstValueFrom(getObject$({
          Bucket: blogger!.editorBucket!,
          Key: `${folder}meta.json`
        }).pipe(
          switchMap(({data}) => !!data?.Body ? from(data.Body.transformToByteArray()) : scheduled([], asyncScheduler))
        )));
        const existingTags = new Set<string>();
        Promise.all(metaFiles).then(metaData => {
          metaData.filter(x => !!x).map(x => {
            const content = enc.decode(x as BufferSource);
            const metaInfo: BlogInfo = JSON.parse(content);
            metaInfo.tags?.forEach(tg => {
              existingTags.add(tg)              
            });
            existingTags.add(metaInfo.title);
          });
          setInitialTags(Array.from(existingTags));
          commonTags.forEach(x => existingTags.add(x));
          const newTags = Array.from(existingTags);
          setTags(newTags);
        })
    }
  }, [parentBlogId, factory, blogger, isEdit]);

  const createBlogOnSubmit = useCallback<(values: FormikValues, _: FormikHelpers<FormikValues>) => void | Promise<any>>((values) => {
    createBlog({ ...{ ...values, image: undefined, videoUrl: undefined }, video: { type: 'youtube', url: values.videoUrl } } as BlogInfo, parentBlogId, values.image);
  }, [createBlog, parentBlogId]);

  const validateForm = useCallback<(values: FormikValues) => void | object | Promise<FormikErrors<FormikValues>>>(values => {
    const errors: any = {};
    if (!values.urlName) {
      errors.name = 'Required';
    }
    if (!values.title) {
      errors.title = 'Required';
    }
    if (!s3KeySafeCharacters.test(values.urlName ?? '')) {
      errors.urlName = s3KeySafeCharactersErrorMessage;
    }
    
    if (!values.image && !post?.titleImage) {
      errors.image = 'Required';
    }
    return errors;
  }, [post]);

  const formikInitialValues = useMemo(() => !!post ? { ...post, videoUrl: post.video?.url } : { ...(blogger.currentItem?.blog ?? { tags: initialTags }), urlName: fileName }, [blogger.currentItem?.blog, fileName, initialTags, post]);

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    //  isSubmitting,
    isValid,
    dirty
    /* and other goodies */ } = useFormik<FormikValues>({
      initialValues: formikInitialValues,
      enableReinitialize: true,
      validate: validateForm,
      onSubmit: createBlogOnSubmit
    });

  const tempBlog = useMemo<PostInfo>(() => ({
    date: moment(),
    id: uuidv4(),
    author: 'no author',
    createdDate: moment(),
    description: values.description ?? post?.description ?? 'Description',
    fullUrl: post?.fullUrl ?? values.urlName ?? '/fake-post-url',
    tags: values.tags ?? post?.tags ?? [],
    title: values.title ?? post?.title ?? 'Title',
    urlName: values.urlName ?? post?.urlName ?? '/fake-post-url',
    titleImage: imageUrl ?? post?.titleImage ?? '/no-image.jpg',
    video: post?.video,
    index: post?.index
  }), [values, post, imageUrl]);

  const onTagsChange = useCallback((_: object, newValue: string[] | null) => {
    setFieldValue('tags', newValue ?? []);
  }, [setFieldValue]);

  const getOptionLabel = useCallback((tag: string) => tag, []);
  const renderInput = useCallback((params: AutocompleteRenderInputParams) => (
    <TextField {...params} name="tags" label="Tags" placeholder="Tags" helperText={errors.tags} error={!!errors.tags && !!touched.tags} />
  ), [errors, touched]);

  const onFilesChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files) {
      const file = e.currentTarget.files[0];
      setFieldValue('image', file);
      setImageUrl(URL.createObjectURL(file));
    }
  }, [setFieldValue, setImageUrl]);

  const onCheckboxChanged = useCallback((_: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFieldValue('index', checked, false);
  }, [setFieldValue]);

  return (<MainContent title={<><Typography variant="h5">{isEdit === true ? <>Edit a</> : <>Create a new</>} {isPost === true ? 'Post' : 'Blog'}</Typography>{!!parentBlogId && <span> At {parentBlogId}</span>}</>}>    
    <form onSubmit={handleSubmit}>
      <Stack spacing={2} direction="row">
        <Stack spacing={2}>
          <TextField name="title" id="title" label="Title" variant="standard"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.title}
            error={!!errors.title && !!touched.title}
            helperText={errors.title}
            />
          <TextField name="urlName" id="urlName" label="Url Name" variant="standard" disabled={(isEdit && !!post)}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.urlName}
            error={!!errors.urlName && !!touched.urlName}
            helperText={errors.urlName}
            />
          <TextField name="description" id="description" label="Description" variant="outlined" 
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            multiline
            error={!!errors.description && !!touched.description}
            helperText={errors.description}
          />
          <TextField name="videoUrl" id="videoUrl" label="Youtube Video Url" variant="outlined" 
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.videoUrl}
            multiline
            error={!!errors.videoUrl && !!touched.videoUrl}
            helperText={errors.description}
          />
          <InputFileUpload name="image" id="image" label="Upload title blog image" accept="image/png, image/jpeg, .svg"
            multiple={false}
            onChange={onFilesChange}
            onBlur={handleBlur}
            error={!!errors.image && !!touched.image}
          />
        <Autocomplete
            multiple
            id="tags"
            value={values.tags ?? []}
            options={tags}
            disableCloseOnSelect={true}
            getOptionLabel={getOptionLabel}
            defaultValue={values.tags ?? []}
            renderInput={renderInput}
            onChange={onTagsChange}
            onBlur={handleBlur}
          />
          {!isPost && <FormControlLabel control={<Checkbox checked={values.index ?? true} onChange={onCheckboxChanged} />} label="Index Child Posts" />}
          {!!blogger.currentItem?.error && <Alert severity="error">{blogger.currentItem?.error?.message}</Alert>}
          <Button type="submit" disabled={!dirty || !touched || !isValid || (blogger.currentItem?.saving === true)} variant="contained">{isEdit ? 'Update' : 'Create'}</Button>
        </Stack>
        <Divider orientation="vertical" />

        <PostCard post={tempBlog} >
          {tempBlog.video?.type === 'youtube' && !!tempBlog.video.url && <iframe height="100%" src={tempBlog.video.url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>}
        </PostCard>

      </Stack>
      </form>
  </MainContent>);
}