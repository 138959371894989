import { combineEpics } from 'redux-observable';
import { authenticationEpic } from './modules/user/authenticationEpic';
import { bloggerEpic } from './modules/user/blogger/indexEpic';
import { appEpic } from './modules/app/appEpic';
import { uiEpic } from './modules/app/uiEpic';

export const rootEpic = combineEpics(
  authenticationEpic,
  bloggerEpic,
  appEpic,
  uiEpic
);
