import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { CustomAppBar } from 'AppBar';
import { NavLink, useNavigate } from 'react-router-dom';
import { UserState } from '@model/user-state';
import { useAuthenticatedActionButtons } from './app-bar/authenticated-actions';
import { useUnauthenticatedActionButtons } from './app-bar/unauthenticated-actions';
import { ProfileActionButton } from './app-bar/profile-action.button';
import { MenuButton } from './app-bar/menu.button';
import { Box, Divider, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { BlogMode } from '@model/blogger-state';
import { MainMenuItem } from '@model/app-state';
import { lazy, PropsWithChildren, useMemo } from 'react';

const HideOnScroll = import.meta.env.SSR ? ({ children }: PropsWithChildren) => <>{children}</> : lazy(() => import('./hide-on-scroll'));

export type ApplicationBarProps = {
  user: UserState,
  drawerIsOpen: boolean | undefined,
  fullScreen: boolean,
  itemsToReview?: number,
  mode: BlogMode,
  mainMenu?: MainMenuItem[],
  signout: () => void,
  signin: () => void,
  closeDrawer?: () => void,
  openDrawer: () => void
}

export function ApplicationBar({ mainMenu, user, fullScreen, signin, signout, openDrawer, drawerIsOpen, mode, itemsToReview = 0 }: ApplicationBarProps) {
  if (!!import.meta.env.SSR) return null;

  const navigate = useNavigate();
  const open = drawerIsOpen === true && !fullScreen;
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('sm'));
  const mainMenuItems = useMemo(() => mainMenu?.map(x => <NavLink key={x.path} to={x.path}>{x.title}</NavLink>) ?? [], [mainMenu]);
  const authenticateButtons = useAuthenticatedActionButtons({ itemsToReview, user, mode });
  const unauthenticatedButtons = useUnauthenticatedActionButtons({ signin });
  const actionButtons = useMemo(() => [...(isMd ? [] : [<MenuButton key="main-menu-items" id="nav-menu-item" icon={<MenuOpenIcon />} anchorOrigin={{ vertical: 'top', horizontal: 'left'}} transformOrigin={{vertical: 'top', horizontal: 'right'}}>{mainMenuItems}</MenuButton>, <Divider key='divider' />]), ...(!!user?.authenticated ? authenticateButtons : unauthenticatedButtons)], [isMd, mainMenuItems, user?.authenticated, authenticateButtons, unauthenticatedButtons]);
  const profileButton = useMemo(() => user.authenticated ? <ProfileActionButton key='Profile' userDisplayName={user.displayName} signout={signout} title='Profile' /> : <></>, [signout, user.authenticated, user.displayName]);

  return (<>
    {!fullScreen &&    
      <HideOnScroll>
        <CustomAppBar open={open}>
          <Toolbar>
            <Box display="flex" alignItems='center'>
                <IconButton
                  onClick={() => openDrawer()}
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  sx={{ mr: 2, ...(drawerIsOpen !== false && { display: 'none' }) }}
                >
                  <MenuIcon />
                </IconButton>
              <Typography
                onClick={() => navigate('/')}
                variant="h6"
                noWrap
                component="div"
                sx={{ display: 'block', cursor: 'pointer' }}
              >
                ArchitectIo
              </Typography>
            </Box>
            {/* <Search /> */}
            <Box sx={{ flexGrow: 1, display: 'flex', alignContent: 'center', justifyContent: 'center' }}  >
              {isMd && <nav id="sidebar">{mainMenuItems}</nav>}
            </Box>
        
            <Box sx={{ display: 'flex' }}>
              {isMd ? <>{actionButtons}{profileButton}</>
                : <>
                  {profileButton}                  
                  <MenuButton>{actionButtons}</MenuButton>
                </>}
            </Box>
          </Toolbar>
        </CustomAppBar>
      </HideOnScroll>
    }
  </>);
}