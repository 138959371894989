import { PayloadAction } from "@reduxjs/toolkit";
import { Epic, combineEpics, ofType } from "redux-observable";
import { filter, map, mergeMap, switchMap, withLatestFrom } from "rxjs/operators";
import { setUser } from "../userSlice";
import { ExecuteBatchActionPayload, clearBlogNodes, executeBatchAction, getCheckedNodes, initialState, removeCheckedNodesAction, setBlogger, toggleBlogNode } from "./bloggerSlice";
import { UserData } from "@model/user-state";
import { from, merge, of, zip } from "rxjs";
import { appConfig } from "app-config";
import { RootState } from "redux/store";
import { selectBlogger } from "../selectors";
import { BloggerState } from "@model/blogger-state";
import { S3ObservableFactory, SsmServiceFactory } from "aws/aws-observable";
import { BlogTreeNode } from "@model/blog-tree-node";
import { LambdaClient, InvocationType, LogType, InvokeCommandInput, InvokeCommand } from "@aws-sdk/client-lambda"; // ES Modules import
import { getAwsServiceClientConfig } from "utils/oauth/login-data";


const setBloggerEpic: Epic<PayloadAction<any>, PayloadAction<any>, RootState> = 
  (action$, state$) => action$.pipe(
  ofType(setUser.type),
  switchMap(({payload}: PayloadAction<UserData>) => {
    const setUserPayload: UserData = payload;
    if (!!setUserPayload) { 
      var ssmClient = new SsmServiceFactory();

      const getParameterObservable = ssmClient.getParameter();

      const parameter$ = zip(getParameterObservable({
        Name: appConfig.editorBucketNameParameterPath,
      }), getParameterObservable({
        Name: appConfig.reviewerBucketNameParameterPath,
      }), getParameterObservable({
        Name: appConfig.publishedBucketNameParameterPath,
      }), getParameterObservable({
        Name: appConfig.publishToReviewLambdaParameterPath,
      }),
      getParameterObservable({
        Name: appConfig.publishLambdaParameterPath,
      })).pipe(
        withLatestFrom(state$.pipe(map(x => selectBlogger(x)))),
        switchMap(([zipped, bloggerState]) => {
          const [[editorErr, editorData], [reviewerErr, reviewerData], [publishedErr, publishedData], [reviewLambdaErr, reviewLambdaData], [publishLambdaErr, publishLambdaData]] = zipped.map(x => [x.error, x.data]);
          const editorBucket = editorErr ? null : editorData!.Parameter?.Value;
          const reviewerBucket = reviewerErr ? undefined : reviewerData!.Parameter?.Value;
          const publishedBucket = publishedErr ? undefined : publishedData!.Parameter?.Value;
          const reviewLambdaName = reviewLambdaErr ? undefined : reviewLambdaData!.Parameter?.Value;
          const publishLambdaName = publishLambdaErr ? undefined : publishLambdaData!.Parameter?.Value;
          const currentBucket = (!!bloggerState.mode ? (bloggerState.mode === "editor" ? editorBucket : reviewerBucket) : undefined);
          const setBloggerAction = !editorBucket 
            ? setBlogger(bloggerState) 
            : setBlogger({...bloggerState, editorBucket: editorBucket, reviewerBucket: reviewerBucket, publishedBucket: publishedBucket, toReviewLambdaName: reviewLambdaName, publishLambdaName, currentBucket: currentBucket! });
          return merge(from([setBloggerAction]), from([toggleBlogNode({ blogItem: null, itemParent: null})]));
        }),        
      );
      
      return parameter$;
    } else {
      return from([setBlogger(initialState)]);
    }
  })
)

const batchActionEpic: Epic<PayloadAction<any>, PayloadAction<any>, RootState> = (action$, state$) => action$.pipe(
  ofType(executeBatchAction.type),
  withLatestFrom(state$.pipe(map(state => selectBlogger(state)))),
  mergeMap(([{ payload }, state]: [PayloadAction<ExecuteBatchActionPayload>, BloggerState]) => {
    // const s3ObservableFactory = new S3ObservableFactory();
    // const list = s3ObservableFactory.listObjectsV2();

    const checkedNodes: BlogTreeNode[] = [];
    getCheckedNodes(state.blogs, checkedNodes);
    const checkedPaths = checkedNodes.map(n => n!.leastNode !== true
      ? `${n!.prefix}`
      : (!!n?.secondaryItems && n!.secondaryItems.length > 0)
        ? [`${n!.prefix}${n!.name}`, ...n.secondaryItems.map(si => `${n!.prefix}${n!.name}${si}`)]
        : `${n!.prefix}${n!.name}`)
      .flat(1);
    const paths$ = of(checkedPaths);
    // .pipe(
    //   mergeMap(n => n!.leastNode !== true && !n!.items
    //     ?  list({
    //         Bucket: state.editorBucket!,
    //         Prefix: n!.prefix
    //       }).pipe(
    //         map(({data, error}) => !!error ? [] : data?.Contents?.map(c => c.Key))
    //       )
    //     : of([`${n!.prefix}${n!.name}`]))
    //);
    return paths$.pipe(
      // reduce((acc, val) => [...(acc ?? []), ...(val ?? [])]),
      map(paths => ({
        paths,
        action: payload.batchAction,
        bucket: state.editorBucket!
      }))
    );
  }),
  mergeMap(({action, paths, bucket}) => {
    if (action === 'publish' || action === 'to-review') {
          var enc = new TextEncoder();      
          const client = new LambdaClient(getAwsServiceClientConfig());      

          const ssmF = new SsmServiceFactory();
          const getParameter = ssmF.getParameter();
          
          const [lambdaNamePath, fromBucketPath, toBucketPath] = action === 'to-review'
            ? [appConfig.publishToReviewLambdaParameterPath, appConfig.editorBucketNameParameterPath, appConfig.reviewerBucketNameParameterPath]
            : [appConfig.publishLambdaParameterPath, appConfig.reviewerBucketNameParameterPath, appConfig.publishedBucketNameParameterPath];

          return zip(getParameter({
              Name: lambdaNamePath
            }).pipe(map(x => !!x.error ? undefined : x.data?.Parameter?.Value)),
            getParameter({
              Name: fromBucketPath
            }).pipe(map(x => !!x.error ? undefined : x.data?.Parameter?.Value)),
            getParameter({
              Name: toBucketPath
            }).pipe(map(x => !!x.error ? undefined : x.data?.Parameter?.Value)))
          .pipe(
            mergeMap(([lambdaFunction, fromS3Bucket, toS3Bucket]) => {
              if (!lambdaFunction || !toS3Bucket || !fromS3Bucket) return of(undefined as unknown as PayloadAction<any>);

              const input = { // InvocationRequest
                FunctionName: lambdaFunction, // required
                InvocationType: InvocationType.RequestResponse,
                LogType: LogType.Tail,
                Payload: enc.encode(JSON.stringify({
                  FromBucket: fromS3Bucket,
                  ToBucket: toS3Bucket,
                  Keys: paths
                })),
                // Qualifier: "dev",
              } as InvokeCommandInput;
              const command = new InvokeCommand(input);
              const send$ = from(client.send(command));
        
              return send$.pipe(
                // map(x => x.FunctionError ?? (x.Payload?.length === 0 ? undefined : JSON.parse(Buffer.from(x.Payload!).toString('utf8')))),
                // map((x: any) => (!x?.errorMessage ? (undefined as unknown) : clearBlogNodes()) as PayloadAction<any>) // TODO: execute error message action to show error if any
                map(() => clearBlogNodes() as PayloadAction<any>)
              );          
            })
          );
    } else if (action === 'remove') {
      const s3ObservableFactory = new S3ObservableFactory();
      const deleteObjects = s3ObservableFactory.deleteObjects();
      const deleteObjects$ = deleteObjects({
        Bucket: bucket,
        Delete: {
          Objects: paths!.map(x => ({ Key: x! }))
        }
      }).pipe(
        map(({ error }) => !!error ? undefined as unknown as PayloadAction<any>
          : removeCheckedNodesAction() as PayloadAction<any>)
      );
      return deleteObjects$;
    }
    return from([undefined as unknown as PayloadAction<any>]);
  }),
  filter(action => {
    console.log(action);
    return !!action
  })
);

export const bloggerEpic = combineEpics(setBloggerEpic, batchActionEpic);

