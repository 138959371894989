import { useMemo } from "react";
import { createPortal } from "react-dom";
import { AttachedCopyButton } from "./attached-copy.button";

export type CopyCodePortalProps = {
    rootElement?: HTMLElement | null
}
export function CopyCodePortal({ rootElement }: CopyCodePortalProps) {
    const elements = useMemo(() => {
        if (!!rootElement) {
          const codeElements = Array.from(rootElement.querySelectorAll('code').values());
          return codeElements.map(x => createPortal(<AttachedCopyButton getTextToCopy={() => x.innerText} />, x));
        } 
    
        return (<></>);
    }, [rootElement]);
    
    return <>{elements}</>;
}