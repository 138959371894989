import { deflateRaw } from 'pako';

function encode64(data: Uint8Array) {
  let r = "";
  for (let i=0; i<data.length; i+=3) {
   if (i+2==data.length) {
  r +=append3bytes(data.at(i)!, data.at(i+1)!, 0);
  } else if (i+1==data.length) {
  r += append3bytes(data.at(i)!, 0, 0);
  } else {
  r += append3bytes(data.at(i)!, data.at(i+1)!, data.at(i+2)!);
  }
  }
  return r;
  }
  
  function append3bytes(b1: number, b2: number, b3: number) {
  const c1 = b1 >> 2;
  const c2 = ((b1 & 0x3) << 4) | (b2 >> 4);
  const c3 = ((b2 & 0xF) << 2) | (b3 >> 6);
  const c4 = b3 & 0x3F;
  let r = "";
  r += encode6bit(c1 & 0x3F);
  r += encode6bit(c2 & 0x3F);
  r += encode6bit(c3 & 0x3F);
  r += encode6bit(c4 & 0x3F);
  return r;
  }
  
  function encode6bit(b: number) {
  if (b < 10) {
   return String.fromCharCode(48 + b);
  }
  b -= 10;
  if (b < 26) {
   return String.fromCharCode(65 + b);
  }
  b -= 26;
  if (b < 26) {
   return String.fromCharCode(97 + b);
  }
  b -= 26;
  if (b == 0) {
   return '-';
  }
  if (b == 1) {
   return '_';
  }
  return '?';
  }
  
  export function compress(s: string) {
    const bytes = new TextEncoder().encode(s);

    const zippedString = deflateRaw(bytes, {
      level: 9,
      // to: 'string',      
    });

    return encode64(zippedString);
  }