
import ConnectedBlogCreator from 'components/blog-creator.connected';
import { ConnectedBlogFileUpload } from 'components/blog-file-upload.connected';
import { ConnectedEditor } from 'components/editor.connected';
import PostEditor from 'components/post-editor';
import { DrawioEditor } from 'components/drawio-editor';
import PlantUmlEditor from 'components/plantuml-editor';
import { ConnectedFileCreator } from 'components/file-creator.connected';
import { ConnectedReviewer } from 'components/reviewer.connected';
import ImageReviewer from 'components/image-reviewer';
import ConnectedPostCreator from 'components/post-creator.connected';
import MetaEditor from 'components/meta-editor';
import { BlogInfo } from '@model/blog-info';
import JsonViewer from 'components/json-viewer';

export const editorRoutes = import.meta.env.SSR ? [] : [
  {
    path: "new/blog/*",
    element: <ConnectedBlogCreator />,
  },
  {
    path: "new/blog",
    element: <ConnectedBlogCreator />,
  },
  {
    path: "new/posts/*",
    element: <ConnectedPostCreator />,
  },
  {
    path: "new/:editorType/*",
    element: <ConnectedFileCreator />,
  },
  {
    path: "upload/blog/*",
    element: <ConnectedBlogFileUpload />,
  },
  {
    path: "edit/posts/*",
    element: <ConnectedEditor>{props => <PostEditor {...props} />}</ConnectedEditor>,
  },
  {
    path: "edit/meta/*",
    element: <ConnectedEditor<BlogInfo> hideHeader={true}>{props => <MetaEditor {...props} />}</ConnectedEditor>,
  },
  {
    path: "edit/drawio/*",
    element: <ConnectedEditor>{props => <DrawioEditor {...props} />}</ConnectedEditor>,
  },
  {
    path: "edit/puml/*",
    element: <ConnectedEditor>{props => <PlantUmlEditor {...props} />}</ConnectedEditor>,
  },
  {
    path: "edit/posts/*",
    element: <ConnectedEditor>{props => <PostEditor {...props} />}</ConnectedEditor>,
  },
  {
    path: "view/meta/*",
    element: <ConnectedReviewer editorBucket={true}>{props => <JsonViewer {...props} />}</ConnectedReviewer>,
  },
  {
    path: "edit/image/*",
    element: <ConnectedReviewer editorBucket={true}>{props => <ImageReviewer {...props} />}</ConnectedReviewer>,
  },
];