import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useMemo, useRef, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import BlogMenuAddPopup from './blog-menu.add.popup';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PublishIcon from '@mui/icons-material/Publish';
import { BlogMode } from '@model/blogger-state';

export enum BlogMenuPopupAction {
  none,
  addBlog,
  addPost,
  addPlantUml,
  addDrawIo,
  remove,
  upload,
  publish,
  toReview,
  view
}

export type BlogMenuPopupProps = {
  anchorElement: null | HTMLElement,
  mode: BlogMode,
  onClose?: (action: BlogMenuPopupAction) => void
}

export default function BlogMenuPopup({anchorElement, mode, onClose}: BlogMenuPopupProps) {
  const [open, setOpen] = useState(Boolean(anchorElement));
  useEffect(() => { if (!!anchorElement && !open) setOpen(true); }, [anchorElement]);
  const addRef = useRef<any>();
  const [addMenuOpen, setAddMenuOpen] = useState(false);
  const isEditor = useMemo(() => mode === "editor", [mode]);

  const handleClose = (action: BlogMenuPopupAction) => {
    setOpen(false);
    if (!!onClose) onClose(action);
  };

  return (
    <Menu
      anchorEl={anchorElement}
      id="blog-menu"
      open={open}
      onClose={_ => handleClose(BlogMenuPopupAction.none)}
      onClick={_ => handleClose(BlogMenuPopupAction.none)}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {isEditor && <MenuItem ref={addRef} onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setAddMenuOpen(!addMenuOpen);
      }} title='Add'>
        <AddCircleOutlineIcon />
        <BlogMenuAddPopup open={addMenuOpen} anchorElement={addRef.current}
          onClose={x => handleClose(x)}></BlogMenuAddPopup>
      </MenuItem>}
      <MenuItem onClick={_ => handleClose(BlogMenuPopupAction.remove)} title='Remove'>
        <DeleteForeverIcon />
      </MenuItem>
      <MenuItem onClick={_ => handleClose(isEditor ? BlogMenuPopupAction.toReview : BlogMenuPopupAction.publish)} title={isEditor ? 'To Review' : 'Publish' }>
        <PublishIcon />
      </MenuItem>
      {isEditor && <MenuItem onClick={_ => handleClose(BlogMenuPopupAction.upload)} title='Upload'>
        <FileUploadIcon />
      </MenuItem>}
   </Menu>
  );
}