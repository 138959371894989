import { EditorProps } from "./editor.connected";
import { useCallback, useMemo } from "react";
import { S3ObservableFactory } from "aws/aws-observable";
import { Observable, firstValueFrom, from, map, mergeMap, of, tap } from "rxjs";
import { BlogCreator, CreateBlogFunc } from "./blog-creator";
import { useNavigate } from "react-router-dom";
import { BlogsEditorPath } from "app-constants";
import moment from "moment";
import { BlogInfo } from "@model/blog-info";
import { UserState } from "@model/user-state";
import { uuidv4 } from "utils/guid";


export type MetaEditorProps = EditorProps & {
  preloadImages?: boolean,
  customPreview?: (data: string) => Promise<string>,
  user?: UserState
};

function MetaEditor({ blogger, data, itemFolder, itemPath, uploadItem, user }: MetaEditorProps) {
  const save = useCallback((content: any) => { if (!!content) { return uploadItem(itemPath!, JSON.stringify({ ...content, date: moment().utc() }), 'application/json'); } }, [uploadItem]);
  const navigate = useNavigate();
  const putObject = useMemo(() => {
    const s3ObservableFactory = new S3ObservableFactory();
    return s3ObservableFactory.putObject();
  }, []);

  const urlName = useMemo(() => {
    const ext = '.md.meta.json';
    if (!!itemPath) {
      const fileName = itemPath.replace(itemFolder!, '');
      return itemPath.endsWith(ext) ? encodeURIComponent(fileName.substring(0, fileName.length - ext.length)) : itemFolder?.split('/').findLast((x: string) => !!x);
    }
    
    return undefined;
  }, [itemPath, itemFolder])

  const metaInfo = useMemo(() => {
    if (!!data && data !== '') {

      const p = JSON.parse(data) as BlogInfo;
      return { ...p, createdDate: moment(p.createdDate), date: moment(p.date), urlName } as BlogInfo
    }
    return !!urlName ? { urlName } as BlogInfo : undefined;
  }, [data, urlName]);

  const isPost = useMemo(() => {
    return itemPath?.endsWith('.meta.json');
  }, [itemPath]);

  const createPostCallback = useCallback<CreateBlogFunc>((blogInfo, parentId, image) => {
    let imageUrl$: Observable<string | undefined>;
    let imageKey: string | undefined = undefined;
    
    if (!!image) {
      imageKey = `${itemFolder}${encodeURIComponent(image!.name)}`;
      imageUrl$ = putObject({
        Bucket: blogger.editorBucket!,
        Key: imageKey,
        Body: image
      }).pipe(
        map(x => (!!x.error ? undefined : imageKey) as string)
      );
    } else {
      imageUrl$ = from([blogInfo.titleImage]);
    }

    const fullUrl = isPost ? `${itemFolder}${urlName}` : itemFolder;

    firstValueFrom(imageUrl$.pipe(mergeMap(imageUrl => of(save({
      ...blogInfo, titleImage: imageUrl, urlName, fullUrl: fullUrl, author: !data ? user?.displayName : blogInfo.author, id: blogInfo.id ?? uuidv4()
     }))), tap(_ => {
      navigate(`/${BlogsEditorPath}/view/meta/${parentId}`);
    })));
  }, [isPost, itemFolder, urlName, putObject, blogger.editorBucket, save, data, user?.displayName, navigate]);

  return (
    <BlogCreator isPost={isPost} blogger={blogger} isEdit={true} post={metaInfo}
      createBlog={createPostCallback} />
  )
}

export default MetaEditor;