import { catchError, from, map, Observable, of, tap } from "rxjs";
import { DeleteObjectCommandInput, DeleteObjectCommandOutput, DeleteObjectsCommandInput, DeleteObjectsCommandOutput, GetObjectCommandInput, GetObjectCommandOutput, ListObjectsV2CommandInput, ListObjectsV2CommandOutput, ListObjectsV2Output, ListObjectsV2Request, PutObjectCommandInput, PutObjectCommandOutput, PutObjectOutput, PutObjectRequest, S3 } from '@aws-sdk/client-s3';
import { HttpHandlerOptions } from "@smithy/types";
import { GetParameterCommandInput, GetParameterCommandOutput, SSM } from "@aws-sdk/client-ssm"
import { getAwsServiceClientConfig, awsClientConfigSubject } from "utils/oauth/login-data";
import { CognitoIdentityProvider, DeleteUserCommandInput, DeleteUserCommandOutput } from "@aws-sdk/client-cognito-identity-provider";

// export type AwsMethodCallback<TOutput> = (err: AWSError, data: TOutput) => void;
export type AwsMethod<TRequest, TResponse> = (params: TRequest, options?: HttpHandlerOptions) => Promise<TResponse>;
export type AwsS3ListObjectMethod = AwsMethod<ListObjectsV2Request, ListObjectsV2Output>;
export type AwsS3PutObjectMethod = AwsMethod<PutObjectRequest, PutObjectOutput>;
export type AwsObservableResponse<TResponse> = {
  data?: TResponse,
  error?: any
}

export class AwsObservable {
  static _create<TRequest, TResponse>(awsMethod: AwsMethod<TRequest, TResponse>, bindTo: any): (params: TRequest) => Observable<AwsObservableResponse<TResponse>> {
    return (params: TRequest) => {
      const caller = awsMethod.bind(bindTo);
      const method$ = from(caller(params));
      const res$ = method$.pipe(
        map<TResponse, AwsObservableResponse<TResponse>>((data) => {
          return {
            data
          };
        }),
        catchError<AwsObservableResponse<TResponse>, Observable<AwsObservableResponse<TResponse>>>((err) => {
          return of({
            error: err
          });
        })
      );
      return res$;
    }
  }
}

export class S3ObservableFactory {
  private service: S3;
  /**
   *
   */
  constructor() {
    this.service = new S3(getAwsServiceClientConfig());
    awsClientConfigSubject.pipe(tap((config) => {      
      this.service = config ? new S3(config) : this.service;
    })).subscribe();
  }

  listObjectsV2(): (params: ListObjectsV2Request) => Observable<AwsObservableResponse<ListObjectsV2CommandOutput>> {
    return AwsObservable._create<ListObjectsV2CommandInput, ListObjectsV2CommandOutput>(this.service.listObjectsV2, this.service);
  }

  putObject(): (params: PutObjectCommandInput) => Observable<AwsObservableResponse<PutObjectCommandOutput>> {
    return AwsObservable._create<PutObjectCommandInput, PutObjectCommandOutput>(this.service.putObject, this.service);
  }

  getObject(): (params: GetObjectCommandInput) => Observable<AwsObservableResponse<GetObjectCommandOutput>> {
    return AwsObservable._create<GetObjectCommandInput, GetObjectCommandOutput>(this.service.getObject, this.service);
  }

  deleteObject(): (params: DeleteObjectCommandInput) => Observable<AwsObservableResponse<DeleteObjectCommandOutput>> {
    return AwsObservable._create<DeleteObjectCommandInput, DeleteObjectCommandOutput>(this.service.deleteObject, this.service);
  }

  deleteObjects(): (params: DeleteObjectsCommandInput) => Observable<AwsObservableResponse<DeleteObjectsCommandOutput>> {
    return AwsObservable._create<DeleteObjectsCommandInput, DeleteObjectsCommandOutput>(this.service.deleteObjects, this.service);
  }
}


export class SsmServiceFactory {
  service: SSM;
  /**
   *
   */
  constructor() {
    this.service = new SSM(getAwsServiceClientConfig());
    awsClientConfigSubject.pipe(tap((config) => this.service = config ? new SSM(config) : this.service)).subscribe();
  }

  getParameter(): (params: GetParameterCommandInput) => Observable<AwsObservableResponse<GetParameterCommandOutput>> {
    return AwsObservable._create<GetParameterCommandInput, GetParameterCommandOutput>(this.service.getParameter, this.service);
  }
}

export class CognitoIdentityServiceFactory {
  service: CognitoIdentityProvider;
  /**
   *
   */
  constructor() {
    this.service = new CognitoIdentityProvider(getAwsServiceClientConfig());
    awsClientConfigSubject.pipe(tap((config) => this.service = config ? new CognitoIdentityProvider(config) : this.service)).subscribe();
  }

  deleteuser(): (params: DeleteUserCommandInput) => Observable<AwsObservableResponse<DeleteUserCommandOutput>> {
    return AwsObservable._create<DeleteUserCommandInput, DeleteUserCommandOutput>(this.service.deleteUser, this.service);
  }
}
