import { RootState } from "../../store";
import { selectBlogger, selectUser } from "../user/selectors";

export const selectUiState = (state: RootState) => state.ui;
export const selectDrawer = (state: RootState) => selectUiState(state)?.drawer;
export const selectAppState = (state: RootState) => state.app;
export const selectPosts = (state: RootState) => selectAppState(state)?.posts;
export const selectCachedPosts = (state: RootState) => {
  return selectAppState(state)?.cachedPosts;
};
export const selectBlogs = (state: RootState) => selectAppState(state)?.blogs;
export const selectMainMenu = (state: RootState) => selectAppState(state)?.mainMenu;
export const selectContentBucket = (state: RootState) => {
  const bucket = selectUser(state)?.isBlogger === true ? selectBlogger(state)?.reviewerBucket : selectBlogger(state)?.publishedBucket;
  return bucket;
}
export const selectFullScreen = (state: RootState) => selectUiState(state)?.fullScreen;
export const selectCompactLayout = (state: RootState) => selectUiState(state)?.compactLayout;
