import { PayloadAction } from "@reduxjs/toolkit";
import { Epic, combineEpics, ofType } from "redux-observable";
import { combineLatestWith, distinct, map, scan, switchMap } from "rxjs/operators";
import { loadMainPage, LoadMainPagePayload, setMainMenu, setMainPageData } from "./appStateSlice";
import { RootState } from "redux/store";
import { selectContentBucket, selectMainMenu } from "./selectors";
import { asyncScheduler, scheduled } from "rxjs";
import { selectUser } from "../user/selectors";
import { MainMenuItem } from "@model/app-state";
import { getMainPage } from "./main-page.data-provider";
import { websiteDomain } from "aws-website-config";

type loadMainPageState = {
  bucket?: string,
  bloggerMode?: boolean;
  mainMenu?: MainMenuItem[]
}
const loadMainPageEpic: Epic<PayloadAction<any>, PayloadAction<any>, RootState> =
  (action$, state$) => action$.pipe(
    ofType(loadMainPage.type),
    combineLatestWith(state$.pipe(
      map(state => ({
        bucket: selectContentBucket(state),
        bloggerMode: selectUser(state)?.isBlogger,
        mainMenu: selectMainMenu(state),
      }) as loadMainPageState),
      scan<loadMainPageState, loadMainPageState>((acc, st) => (acc.bucket != st.bucket || acc.bloggerMode !== st.bloggerMode) ? st : acc, {}),
      distinct()
    )),
    switchMap(([{ payload }, { bucket, bloggerMode, mainMenu }]: [PayloadAction<LoadMainPagePayload>, loadMainPageState]) => {
      return getMainPage(bloggerMode, bucket, mainMenu, payload.page, websiteDomain);
    }),
    switchMap(({ posts, blogs, mainMenu }) => {
      const mainMenuAction = !!mainMenu ? [setMainMenu({ mainMenu })] : [];
      return scheduled([setMainPageData({
        posts,
        blogs
      }), ...mainMenuAction], asyncScheduler);
    })
)


export const appEpic = combineEpics(loadMainPageEpic);