import { AppState, MainMenuItem } from '@model/app-state';
import { BlogInfo } from '@model/blog-info';
import { PostInfo } from '@model/post-info';
import * as toolkit from '@reduxjs/toolkit'

export const initialState: AppState = {
  selectedBlog: 'architecture',
  cachedPosts: {}
};

export type SetBlogsPayload = {
  posts: PostInfo[],
  blogs: BlogInfo[]
}

export type SetMainMenuPayload = {
  mainMenu: MainMenuItem[],
}

export type LoadMainPagePayload = {
  page?: string
}

const appStateSlice = toolkit.createSlice({
  name: 'app-state',
  initialState,
  reducers: {
    loadMainPage: (state: AppState, _: toolkit.PayloadAction<LoadMainPagePayload>) => state,
    setMainMenu: (state: AppState, { payload }: toolkit.PayloadAction<SetMainMenuPayload>) => {
      return { ...state, ...payload };
    },
    setMainPageData: (state: AppState, { payload }: toolkit.PayloadAction<SetBlogsPayload>) => {
      return {...state, ...payload };
    }
  }
})

export const { loadMainPage, setMainPageData, setMainMenu } = appStateSlice.actions;
export default appStateSlice.reducer as toolkit.Reducer<AppState>;