import './home-page.scss';
import { useAppSelector } from "redux/hooks";
import { selectBlogs, selectPosts } from "redux/modules/app/selectors";
import HomePage from "./home-page";
import { selectUser } from 'redux/modules/user/selectors';

function ConnectedHomePage() {
  const posts = useAppSelector( selectPosts ) ?? [];
  const blogs = useAppSelector( selectBlogs ) ?? [];
  const user = useAppSelector( selectUser ) ?? [];

  return (<HomePage {...{posts, blogs, isBlogger: user.isBlogger}} />);
}

export default ConnectedHomePage;