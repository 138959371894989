
import Badge from '@mui/material/Badge';
import { BlogsEditorPath, BlogsReviewerPath } from 'app-constants';
import RateReviewIcon from '@mui/icons-material/RateReview';
import { UserState } from '@model/user-state';
import { IconButton } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { BlogMode } from '@model/blogger-state';
import { FileManager } from 'utils/file-manager';

export type AuthenticatedActionButtonsProps = {
    user: UserState,
    mode: BlogMode,
    itemsToReview?: number
};

export function useAuthenticatedActionButtons({ user, itemsToReview, mode }: AuthenticatedActionButtonsProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [editorNavigateLink, reviewerNavigateLink] = useMemo(() => {        
      const editorPath = location.pathname.startsWith(`/${BlogsReviewerPath}/`)
          ? location.pathname.replace(`${BlogsReviewerPath}/view`, `${BlogsEditorPath}/edit`).replace(`${BlogsReviewerPath}/`, `${BlogsEditorPath}/`)
          : BlogsEditorPath;
      const reviewerPath = location.pathname.startsWith(`/${BlogsEditorPath}/`)
          ? location.pathname.replace(`${BlogsEditorPath}/edit`, `${BlogsReviewerPath}/view`).replace(`${BlogsEditorPath}/`, `${BlogsReviewerPath}/`)
        : BlogsReviewerPath;
    
    const reducedEditorPath = editorPath.substring(0, editorPath.lastIndexOf('.'));
    const editorType = FileManager.getEditorType(reducedEditorPath);

    const reviewerType = FileManager.getEditorType(reviewerPath);

    return [!!editorType ? reducedEditorPath : editorPath,
          !!reviewerType && reviewerType !== 'posts' ? `${reviewerPath}${FileManager.getImageExtension(reviewerType) ?? ''}` : reviewerPath];
  }, [location.pathname]);

  const children = useMemo(() => [...(user.isBlogger ? [
      <IconButton  key='editor' size="large" color="inherit" onClick={() => navigate(editorNavigateLink)} title='Editor' disabled={mode === "editor"}>
        <ModeEditIcon />  
      </IconButton>,
      <IconButton key='reviewer' size="large" aria-label={`Review ${itemsToReview} new items`} color="inherit" title='Review'
          onClick={() => navigate(reviewerNavigateLink)}
          disabled={mode === "reviewer"}>
            <Badge badgeContent={itemsToReview} color="error" showZero={false}>
                <RateReviewIcon />
            </Badge>
        </IconButton>]
    : [])
    ],
      [user.isBlogger, mode, itemsToReview, navigate, editorNavigateLink, reviewerNavigateLink]);

  return children;
}