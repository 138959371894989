import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
import { PostInfo } from '@model/post-info';
import { Box, CardActionArea, CardActions, Collapse, IconButton, IconButtonProps, styled } from '@mui/material';
import { PropsWithChildren, useCallback, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export type PostCardProps = PropsWithChildren & {
  post: PostInfo,
  onNavigate?: (post: PostInfo) => void,
  spacing?: number
}

export default function PostCard({ post, spacing, onNavigate, children }: PostCardProps) {
  const navigate = useCallback(() => {
    if (!!onNavigate) onNavigate(post);
  }, [onNavigate, post]);
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = useCallback(() => {
    setExpanded(prevExpanded => !prevExpanded);
  }, []);

  return (!!import.meta.env.SSR ? <Box sx={{ maxWidth: 345, minWidth: 345, m: spacing ?? 0 }}>
    <div className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiCard-root css-1bqacs0-MuiPaper-root-MuiCard-root">
      <button className="MuiButtonBase-root MuiCardActionArea-root css-1oitmrd-MuiButtonBase-root-MuiCardActionArea-root" tabIndex={0} type="button">
        <img className="MuiCardMedia-root MuiCardMedia-media MuiCardMedia-img css-o69gx8-MuiCardMedia-root" src={post.titleImage} height="194" />
        <div className="MuiCardContent-root css-46bh2p-MuiCardContent-root">
          <div className="MuiTypography-root MuiTypography-h5 MuiTypography-gutterBottom css-rprrz2-MuiTypography-root">{post.title}</div>
          <p className="MuiTypography-root MuiTypography-body2 css-1bp9h1s-MuiTypography-root">{post.description}</p>
        </div>
        <span className="MuiCardActionArea-focusHighlight css-1v2exvi-MuiCardActionArea-focusHighlight"></span><span className="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span></button>
    </div>
  </Box> :
    <Card sx={{ maxWidth: 345, minWidth: 345, m: spacing ?? 0 }}>
      <CardActionArea onClick={navigate}>
        <CardMedia
          component="img"
          height="194"
          image={post.titleImage}
        />
        <CardContent>
        <Typography gutterBottom variant="h5" component="div">
            {post.title}
         </Typography>
        <Typography variant="body2" color="text.secondary">
            {post.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      {!!children && <><CardActions disableSpacing>
        {/* <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton> */}
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {children}
        </CardContent>
        </Collapse>
      </>}
    </Card>
  );
}