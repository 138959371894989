import { OpenGraphAttributes } from "@model/open-graph";
import { PostInfo } from "@model/post-info";

export function metaToOpenGraphAttributes(meta: PostInfo, contentHttpFullUrl: string, currentPageUrl: string): OpenGraphAttributes {
  return {
    title: meta.title,
    type: "website",
    description: meta.description,
    image: `${contentHttpFullUrl}${meta.titleImage}`,
    url: currentPageUrl
  };
}
