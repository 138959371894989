import { useCallback, useEffect, useState } from "react";
import { ReviewerProps } from "./reviewer.connected";
import { Box, Button } from "@mui/material";
import { toHtmlConverter } from "./supportedLanguages";
import { preloadImages } from "utils/pre-signed-url-converter";
import 'highlight.js/styles/github-dark.css';
import { TitleMedia } from "./title-media";
import { BlogInfo } from "@model/blog-info";
import { useSignedUrl } from "utils/use-signed-url.hook";

export type PostReviewerProps = ReviewerProps<string> & {
};

function PostReviewer({ blogger, data, itemPath, fetchItem, refreshItem }: PostReviewerProps) {
  const [htmlContent, setHtmlContent] = useState<string>('<span>loading...</span>');
  const [meta, setMeta] = useState<BlogInfo | null>();
  const signUrl = useSignedUrl(blogger.currentBucket, blogger?.blogsLocation);
  const refreshMeta = useCallback(async () => {
    setMeta(null);
    if (!!blogger.currentBucket) {
      const content = await fetchItem(`${itemPath}.meta.json`, blogger.currentBucket!);
      const metaInfo = JSON.parse(content!) as BlogInfo;
      metaInfo.titleImage = (await signUrl(metaInfo.titleImage))!;
      setMeta(metaInfo);
    }
  }, [itemPath, fetchItem, blogger?.currentBucket]);

  useEffect(() => {
    refreshMeta();
  }, [refreshMeta]);

  const onRefreshClick = useCallback(() => {
    refreshItem();
    refreshMeta();
  }, [refreshItem, refreshMeta]);
  
  useEffect(() => {
    if (!data) return;

    const prefix = itemPath!.substring(0, itemPath!.lastIndexOf("/"));
    preloadImages(blogger.currentBucket!, prefix, data!).then((updatedText) => {
      setHtmlContent(toHtmlConverter.parse(updatedText) as string);
    })
  }, [data]);
  return (<>
    <span>post viewer: {itemPath}</span> <Button onClick={onRefreshClick} >refresh</Button>
    <Box>
      <TitleMedia blog={meta} isBlogger={true} />
      {!!htmlContent && <div dangerouslySetInnerHTML={{ __html: htmlContent }} />}
    </Box>
  </>)
}

export default PostReviewer;