import { BloggerState } from "@model/blogger-state";
import { Typography } from "@mui/material";
import { S3ObservableFactory } from "aws/aws-observable";
import { ReactElement, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectBlogger } from "redux/modules/user/selectors";
import { firstValueFrom, map } from "rxjs";
import { MainContent } from "./main-content";
import { updateBlogNode } from "redux/modules/user/blogger/bloggerSlice";
import { BlogTreeNode } from "@model/blog-tree-node";
import { fullScreen } from "redux/modules/app/uiStateSlice";
import { selectFullScreen } from "redux/modules/app/selectors";
import { useDebounceEffect } from "utils/debounce-effect.hook";
import { PutObjectCommandOutput } from "@aws-sdk/client-s3";

export type EditorFetchItemFunc = (itemPath: string, bucket: string) => Promise<string | null>;

export type EditorProps = {
  blogger: BloggerState,
  data: string | null,
  itemFolder: string | undefined,
  itemPath: string | undefined,
  fetchItem: () => Promise<string|null>, 
  uploadItem: (filePath: string, fileData: string | Buffer, contentType?: string) => Promise<PutObjectCommandOutput | undefined>,
  fullScreen: boolean,
  setFullScreen: (fullScreen: boolean) => void,
  isNewFile?: boolean,
  api: {
    fetchItem: EditorFetchItemFunc, 
  }
}

export type ConnectedEditorProps<TContent> = {
  autoloading?: boolean,
  encoder?: (data: BufferSource | undefined) => TContent
  children: (props: EditorProps) => ReactElement<any, any>,
  isNewFile?: boolean,
  newFileName?: string,
  hideHeader?: boolean
}

export function ConnectedEditor<TContent = string>({children, encoder, autoloading, isNewFile, newFileName, hideHeader}: ConnectedEditorProps<TContent>) {
  const blogger = useAppSelector(selectBlogger);
  const fullScreenState = useAppSelector(selectFullScreen);

  const dispatch = useAppDispatch();
  const [data, setData] = useState<string|null>(null);

  const params = useParams();
  const itemId = useMemo(() => 
    params['*'], [params]);
  const itemName = useMemo(() => {
    const parts = itemId?.split('/');
    setData(null);
    return !!parts ? parts[parts.length - 1] : null;
  }, [itemId]);
  const itemFolder = useMemo(() => itemId?.replace(itemName!, ''), [itemId, itemName]);
  const factory = useMemo(() => !!blogger.editorBucket ? new S3ObservableFactory() : null, [blogger.editorBucket]);
  const currentEncoder = useMemo(() => !!encoder ? encoder 
    : (bufferData: BufferSource | undefined) => {
      const enc = new TextDecoder();
      const content = enc.decode(bufferData);
      return typeof content == typeof '' ? content as unknown as TContent : null;
    }, [encoder]);

  const [fetchUserItemFlag, setFetchUserItemFlag] = useState(false);

  const fetchItem = useCallback<EditorFetchItemFunc>((async (itemPath: string, bucket: string) => {
    const getObject = factory!.getObject();
    const getObject$ = getObject({
      Bucket: bucket,
      Key: `${blogger.blogsLocation}${itemPath}`
    }).pipe(
      map(({data, error}) => {
        if (!!error) console.error(error);
        return data;
      })
    );
    const fetchResponse = await firstValueFrom(getObject$);
    const content = currentEncoder(await fetchResponse?.Body?.transformToByteArray() as BufferSource)
    return !content ? undefined : content;
  }) as EditorFetchItemFunc, [blogger.blogsLocation, factory, currentEncoder]);

  const fetchUserItem = useCallback(async () => {
    if (!!itemId && !!blogger?.editorBucket) {
      const content = await fetchItem(itemId!, blogger!.editorBucket!)
      
      setData(content);        
      return content;
    } else {
      setFetchUserItemFlag(true);
    }
    return null;
  }, [itemId, blogger?.editorBucket, fetchItem]);

  useDebounceEffect(() => {
    if (!isNewFile && (((fetchUserItemFlag || autoloading !== false) && !data))) {
      fetchUserItem();
    }
  }, [itemId, fetchUserItemFlag, autoloading, isNewFile, data, fetchUserItem]);

  const uploadItem = useCallback(async (filePath: string, fileData: string | Buffer, contentType?: string) => {
    const putObject = factory!.putObject();
    
    const putObject$ = putObject({
      Bucket: blogger.editorBucket!,
      Key: `${blogger.blogsLocation}${filePath}`,
      Body: fileData,
      ContentType: contentType
    }).pipe(
      map(({ data, error }) => {
        if (!!error) console.error(error);
        return data;
      })
    );
    const response = await firstValueFrom(putObject$);
    const rootFolder = isNewFile ? `${itemId}/` : itemFolder;
    dispatch(updateBlogNode({
      newItem: {
        leastNode: true,
        name: filePath.replace(rootFolder!, ""),
        prefix: rootFolder,
        level: (rootFolder?.split("/").length ?? 1 ) - 1
      } as BlogTreeNode,
      parent: rootFolder
    }));
    return response;
  }, [factory, blogger?.editorBucket, blogger.blogsLocation, itemId]);

  // const pushToReview = useCallback(() => dispatch(executeBatchAction({ batchAction: "to-review" })), [dispatch]);

  const setFullScreen = useCallback((fs: boolean) => dispatch(fullScreen(fs)), [dispatch]);

  return (
    <>
      {isNewFile === true ? <>
        {hideHeader !== true && <Typography variant="body2">at: {itemId}</Typography>}
        {children({blogger, data, itemPath: `${itemId}/${newFileName}`, fetchItem: fetchUserItem, uploadItem, isNewFile, fullScreen: fullScreenState, setFullScreen, itemFolder, api: { fetchItem }})}
      </>
      : <MainContent fullScreen={fullScreenState} fullWidth noMargin title={hideHeader !== true && !fullScreen ? <span><Typography variant="h5">Editing: {itemName}</Typography>
          <Typography variant="subtitle2">at {itemFolder}</Typography> </span> : undefined}>
          {/* <Button variant="contained" onClick={pushToReview}>Push to Review</Button> */}
      {children({blogger, data, itemPath: itemId, fetchItem: fetchUserItem, uploadItem, fullScreen: fullScreenState, setFullScreen, itemFolder, api: { fetchItem }})}
      </MainContent>}
    </>);
}