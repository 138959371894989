import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { EditorProps } from "./editor.connected";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";

export type DrawioEditorProps = EditorProps & {
}
const url = 'https://embed.diagrams.net/?embed=1&ui=atlas&spin=1&modified=unsavedChanges&proto=json';

// embed mode: https://www.diagrams.net/doc/faq/embed-mode
export function DrawioEditor({blogger, itemPath, uploadItem, fullScreen, setFullScreen, ...props}: DrawioEditorProps) {
  const [data, setData] = useState(props.data);
  const containerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const iframe = useMemo<HTMLIFrameElement|null>(() => {
    var newIframe = document.createElement('iframe');
    newIframe.setAttribute('frameborder', '0');
    newIframe.setAttribute('width', '100%');
    newIframe.style.height = '100%';
    return newIframe;
  }, []);

  // handle props data changes
  useEffect(() => {
    if (data != props.data) setData(props.data);
  }, [props.data]);

  useEffect(() => {
    if (!!containerRef.current && iframe) {
      containerRef.current!.appendChild(iframe);
      iframe?.setAttribute('src', url);

      return () => {
        iframe?.removeAttribute('src');
        if (containerRef.current?.contains(iframe)) containerRef.current?.removeChild(iframe);
      }
    }
  }, [containerRef.current, iframe, data]);

  const receive = useCallback((evt: MessageEvent) =>
  {
    if (evt.data.length > 0 && evt.source == iframe?.contentWindow)
    {
      var msg = !!evt.data ? JSON.parse(evt.data) : {};
      // Received if the editor is ready
      if (msg.event == 'init')
      {
        iframe!.contentWindow!.postMessage(JSON.stringify(
          {action: 'load', xml: data}), '*');      
      }
      // Received if the user clicks save
      else if (msg.event == 'save')
      {
        uploadItem(itemPath!, msg.xml, 'application/xml');
        // Sends a request to export the diagram as XML with embedded PNG
        iframe?.contentWindow?.postMessage(JSON.stringify(
          {action: 'export', format: 'svg', spinKey: 'saving'}), '*');
      }
      // Received if the export request was processed
      else if (msg.event == 'export')
      {
          const base64 = msg.data.substring('data:image/svg+xml;base64,'.length);
          const svg = atob(base64);
          uploadItem(`${itemPath}.svg`, svg, 'image/svg+xml');
          setData(msg.xml);          
      }

      // Received if the user clicks exit or after export
      if (msg.event == 'exit')
      {
        navigate('..');
      }
    }
  }, [iframe, data, fullScreen]);

  useEffect(() => {
    window.addEventListener('message', receive);
    return () => window.removeEventListener('message', receive);
  }, [receive]);


  return (<>
    <Button fullWidth variant="contained" color={fullScreen ? "primary" : "info"} onClick={() => setFullScreen(!fullScreen)}>{fullScreen ? "Exit Full Screen" : "Full Screen"}</Button>
    <div ref={containerRef} style={{width: '100%', height: '100vh', paddingBottom: '30px'}}></div>
  </>)

}