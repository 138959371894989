import React, { useCallback, useMemo, useRef, useState } from 'react';

import { ButtonGroup, Checkbox, Collapse, IconButton, List, ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import BookIcon from '@mui/icons-material/Book';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import PublishIcon from '@mui/icons-material/Publish';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PostAddIcon from '@mui/icons-material/PostAdd';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { BlogTreeNode } from '@model/blog-tree-node';
import BlogMenuPopup, { BlogMenuPopupAction } from './blog-menu.popup';
import { To, useNavigate } from 'react-router-dom';
import { prefixToBlogId } from 'utils/endoders';
import { FileManager } from 'utils/file-manager';
import { ExecuteBatchActionTypes } from 'redux/modules/user/blogger/bloggerSlice';
import { BlogsEditorPath, BlogsReviewerPath } from 'app-constants';
import { BlogMode } from '@model/blogger-state';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export type BlogTreeItemProps = {
  item: BlogTreeNode,
  parent?: BlogTreeNode,
  mode: BlogMode,
  itemClicked?: (item: BlogTreeNode, parent?: BlogTreeNode) => void,
  isSelecting?: boolean,
  selectItems?: (item: BlogTreeNode, select: boolean) => void,
  clearSelectedItems: () => void,
  executeBatchAction: (action: ExecuteBatchActionTypes) => void,
  onNavigate?: (navPath: string) => void
}

export function BlogTreeItem({item, itemClicked, parent, isSelecting, mode, selectItems, executeBatchAction, onNavigate, clearSelectedItems }: BlogTreeItemProps) {
  const open = item?.open ?? false;
  const itemClickedFunc = useMemo(() => itemClicked ?? (() => { }), [itemClicked]);
  const itemClickedHandler = useCallback((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    itemClickedFunc(item, parent);
  }, [itemClickedFunc, item, parent]);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const navigateWrapper = useCallback((to: To) => {
    if (!!onNavigate) onNavigate(to.toString());
    return navigate(to);
  }, [onNavigate]);
  
  const itemsLoading = item?.loadingItems ?? false;
  const [batchAction, setBatchAction] = useState<ExecuteBatchActionTypes|undefined>(undefined)
  const handleMenuClose = useCallback((action: BlogMenuPopupAction) => {
    setMenuOpen(false);
    const blogId = prefixToBlogId(item?.prefix);
    if (action === BlogMenuPopupAction.addBlog) {
      navigateWrapper(`/${BlogsEditorPath}/new/blog/${blogId}`)
    } else if (action === BlogMenuPopupAction.upload) {
      navigateWrapper(`/${BlogsEditorPath}/upload/blog/${blogId}`)
    } else if (action === BlogMenuPopupAction.addPost) {
      navigateWrapper(`/${BlogsEditorPath}/new/posts/${blogId}`)
    } else if (action === BlogMenuPopupAction.addDrawIo) {
      navigateWrapper(`/${BlogsEditorPath}/new/drawio/${blogId}`)
    } else if (action === BlogMenuPopupAction.addPlantUml) {
      navigateWrapper(`/${BlogsEditorPath}/new/puml/${blogId}`)
    } else if (action === BlogMenuPopupAction.publish) {
      setBatchAction('publish');
      if (!!selectItems) selectItems(item, true);
    } else if (action === BlogMenuPopupAction.toReview) {
      setBatchAction('to-review');
      if (!!selectItems) selectItems(item, true);
    } else if (action === BlogMenuPopupAction.remove) {
      setBatchAction('remove');
      if (!!selectItems) selectItems(item, true);
    } 
  }, [item, navigateWrapper, selectItems]); 
  const isReadOnly = useMemo(() => item!.leastNode && !FileManager.getEditorType(item!.name), [item, mode]);
  const theme = useTheme();

  var ephemeralItem = item;
  if (itemsLoading) {
    const loadingItem: BlogTreeNode = { name: 'Loading...', open: false, level: item!.level + 1, leastNode: true  } as BlogTreeNode;
    ephemeralItem = {...item!, items: [loadingItem] }; 
  }
  const menuIconRef = useRef(null);

  const confirmBatchAction = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    executeBatchAction(batchAction!);
    setBatchAction(undefined);
  }, [batchAction, executeBatchAction]);

  const cancelBatchAction = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    setBatchAction(undefined);
    if (clearSelectedItems) clearSelectedItems();
  }, []);

  const onMenuItemClicked = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    setMenuOpen(true);
  }, []);

  
  const onCheckboxClicked = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // event.preventDefault();
    event.stopPropagation();
  }, []);  
  
  const isPostItem = useMemo(() => item!.name.endsWith('.md'), [item]);
  const hasMetaData = useMemo(() => isPostItem ? item?.secondaryItems?.find(x => x.endsWith('.meta.json')) : false, [isPostItem, item?.secondaryItems]);
  const isCurSelected = isSelecting || !!batchAction;

  const onPostMenuItemClicked = useCallback((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();

    const editorType = 'meta';
    const blogId = item?.prefix;
    const itemId = item?.name;
    const navPath = mode === 'editor'
      ? `/${BlogsEditorPath}/edit/${editorType}/${blogId ?? ''}${itemId}.meta.json`
      : `/${BlogsReviewerPath}/view/${editorType}/${blogId ?? ''}${itemId}.meta.json`;
    clearSelectedItems();
    if (selectItems) selectItems(item, true);
    navigateWrapper(navPath);
  }, [hasMetaData, item, navigateWrapper, mode]);
  
  return <>
    <ListItemButton sx={{ pl: 4 * (item?.level ?? 0) }} onClick={itemClickedHandler}>
      {isCurSelected && <Checkbox size='small' onClick={onCheckboxClicked}  checked={item?.checked ?? true} onChange={e => !!selectItems && selectItems(item, e.target.checked)}/>}
      <ListItemIcon sx={{ minWidth: 34 }}>
        {!item?.leastNode ? <MenuBookIcon /> : <BookIcon />}
      </ListItemIcon>
      <ListItemText primary={decodeURIComponent(item!.name)} primaryTypographyProps={isReadOnly ? {
            color: theme.palette.grey[500]
          } : {
            color: theme.palette.primary.main
          }} 
        secondary={!!item?.leastNode && !!item?.secondaryItems ? ` - (${item?.secondaryItems.join(',')})` : null} />
      {isPostItem && <IconButton color="secondary" aria-label="Edit Post Info" onClick={onPostMenuItemClicked}>
        {hasMetaData ? <EditNoteIcon /> : <PostAddIcon />}
      </IconButton>}
      {!(item?.leastNode) && (<>
          {!isCurSelected ? <IconButton color="secondary" aria-label="menu" onClick={onMenuItemClicked}>
            <MenuIcon ref={menuIconRef} />
          </IconButton>
          : <>
          {!!batchAction && 
            <ButtonGroup size="small" aria-label="small button group">
              <IconButton onClick={cancelBatchAction}><CloseIcon /></IconButton>
              <IconButton onClick={confirmBatchAction}>{batchAction == 'remove' ? <DeleteForeverIcon htmlColor='red'/> : <PublishIcon color='primary'/>}</IconButton>            
            </ButtonGroup>
          }
          </>}
          {open ? <ExpandLess /> : <ExpandMore />}
        </>)
      }
    </ListItemButton>
    <Collapse in={open || itemsLoading} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {ephemeralItem?.items?.map(x => <BlogTreeItem key={x?.name} item={x} itemClicked={itemClicked} mode={mode} parent={item} isSelecting={isCurSelected} selectItems={selectItems} executeBatchAction={executeBatchAction} onNavigate={onNavigate} clearSelectedItems={clearSelectedItems}/>)}
      </List>
    </Collapse>
    {menuOpen && <BlogMenuPopup anchorElement={menuIconRef.current} onClose={handleMenuClose} mode={mode} />}
  </>
}