const reportWebVitals = async () => {
  const onPerfEntry = console.log;
  if (!!onPerfEntry) {
    const vitals = await import('web-vitals');

    const { onCLS, onFCP, onLCP, onTTFB } = vitals;
   
    onCLS(onPerfEntry);
    onFCP(onPerfEntry);
    onLCP(onPerfEntry);
    onTTFB(onPerfEntry);
  }
};

export default reportWebVitals;
