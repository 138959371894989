import * as toolkit from "@reduxjs/toolkit";
import { combineEpics, Epic, ofType } from "redux-observable";
import { filter, map, mergeMap, reduce, startWith, withLatestFrom } from "rxjs/operators";
import { ToggleBlogNodePayload, updateBlogNode, toggleBlogNode, createBlog, CreateBlogPayload, setCurrentBlog, createPost } from "./bloggerSlice";
import { Observable, from, merge } from "rxjs";
import { S3ObservableFactory } from "aws/aws-observable";
import { BlogTreeNode } from "@model/blog-tree-node";
import { BloggerState } from "@model/blogger-state";
import { RootState } from "redux/store";
import { selectBlogger, selectUser } from "../selectors";
import { getParentLevelFromId, prefixToBlogId } from "utils/endoders";
import { FileManager } from "utils/file-manager";
import { UserState } from "@model/user-state";
import moment from "moment";
import { uuidv4 } from "utils/guid";
import { ListObjectsCommandInput } from "@aws-sdk/client-s3";

type BlogTreeRequest = {
  s3Request: ListObjectsCommandInput,
  item: BlogTreeNode
};

const getBlogList = (request: BlogTreeRequest) => {
    const s3ObservableFactory = new S3ObservableFactory();
    const listObjectsV2 = s3ObservableFactory.listObjectsV2();
    return listObjectsV2(request!.s3Request).pipe(
      map(response => {
        const parent = request!.item;
        const itemsLevel = !parent ? 0 : parent.level + 1;
        const folders = !response 
        ? [] as BlogTreeNode[]
          : response.data?.CommonPrefixes?.map(y => ({
            open: false,
            name: !parent ? y.Prefix : y.Prefix?.replace(parent?.prefix, ''),
            prefix: y.Prefix,
            leastNode: false,
            parent: parent,
            level: itemsLevel 
          } as BlogTreeNode)) ?? [] as BlogTreeNode[];
        const files = !response
          ? [] as BlogTreeNode[]
          : response.data?.Contents?.map(y => ({
            open: false,
            name: !parent ? y.Key : y.Key?.replace(parent?.prefix, ''),
            prefix: parent?.prefix,
            parent: parent,
            leastNode: true,
            level: itemsLevel
          } as BlogTreeNode)) ?? [] as BlogTreeNode[];
          const editorFiles = files.filter(x => !!FileManager.getEditorType(x?.name!));
          const groupedFiles = files.reduce((acc, f) => {
            if (!FileManager.getEditorType(f!.name)) {
              // find group
              const efi = editorFiles.findIndex(ef => f!.name.startsWith(ef!.name));
              if (efi >= 0) {
                const editorFile = editorFiles[efi];
                editorFiles[efi]!.secondaryItems = [...(editorFile?.secondaryItems ?? []), f!.name.replace(editorFile?.name!, '')];
                return acc;
              }
            }
            return [...acc, f];
          }, [] as BlogTreeNode[])
        return { parent: parent, resultItems: [...folders, ...groupedFiles.filter(f => f?.name !== "")] };
      })
    )};

    
const blogListRequestsIssuerEpic: Epic<toolkit.PayloadAction<any>, toolkit.PayloadAction<any>, RootState> = (action$, state$) => action$.pipe(
  ofType(toggleBlogNode.type),
  withLatestFrom(state$.pipe(map(state => selectBlogger(state)))),
  filter(([_, blogger]) => !!blogger?.editorBucket),
  mergeMap(([action, blogger]: [toolkit.PayloadAction<ToggleBlogNodePayload>, BloggerState]) => {
    const { blogItem } = action.payload;

    var s3Request: ListObjectsCommandInput = {
      Bucket: blogger.currentBucket!, /* required */
      Delimiter: '/',
      Prefix: blogItem?.prefix ?? blogger.blogsLocation
    };

    return blogItem?.open === true || !!blogItem?.items
    ? from([updateBlogNode({
      newItem: {...blogItem, open: !blogItem?.open}
    })])
    : getBlogList({s3Request, item: blogItem}).pipe(
      map(({parent, resultItems}) => {
        if (!parent) {
          return updateBlogNode({
            newItem: null,
            rootItems: resultItems,
          });
        } else {
          return updateBlogNode({
            newItem: {...parent, items: resultItems, open: true },
          });
        }
      }),
      startWith(updateBlogNode({
        newItem: blogItem ? {...blogItem, loadingItems: true, open: true } : blogItem,
        rootItems: !blogItem ? [{ name: 'Loading...', open: false, leastNode: true } as BlogTreeNode] : undefined,
      })),
    )
  })
);

const createBlogEpic: Epic<toolkit.PayloadAction<any>, toolkit.PayloadAction<any>, RootState> = (action$, state$) => action$.pipe(
  ofType(createBlog.type, createPost.type),
  withLatestFrom(state$.pipe(map(state => ({ blogger: selectBlogger(state), user: selectUser(state) })))),
  mergeMap(([{ payload, type }, { blogger, user }]: [toolkit.PayloadAction<CreateBlogPayload>, { blogger: BloggerState, user: UserState }]) => {
    const isBlog = type === createBlog.type;
    const { blogInfo, parent, content, image } = payload;
    const blogLocation = !!parent ? `${prefixToBlogId(parent)}/` : blogger.blogsLocation
    const fileName = isBlog ? `${blogInfo.urlName}/` : `${blogInfo.urlName}.md`;
    const newItemKey = `${blogLocation}${isBlog ? blogInfo.urlName + '/' : ''}`;
    const parentLevel = getParentLevelFromId(parent);

    const s3ObservableFactory = new S3ObservableFactory();
    const putObject = s3ObservableFactory.putObject();
    const updatedTags = new Set<string>(blogInfo.tags ?? []);
    if (isBlog) updatedTags.add(blogInfo.title);

    const createdDate = moment().utc();

    const templateFiles = (imageUrl?: string) => [
      ...(isBlog ? [] : [{
        key: fileName,
        content: `# ${blogInfo.description}`,
        type: 'text/plain'
      }]),
      ...[{
        key: isBlog ? 'meta.json' : `${fileName}.meta.json`,
        type: 'application/json',
        content: {
          ...(!!imageUrl ? { ...blogInfo, titleImage: imageUrl } : blogInfo),
          id: uuidv4(),
          author: user.displayName,
          fullUrl: `${blogLocation}${blogInfo.urlName}`,
          tags: Array.from(updatedTags),
          createdDate,
          date: createdDate
      }}]];

    let imageKey: string | undefined = undefined;
    let imageUrl$: Observable<string | undefined>;
    let imageItem: BlogTreeNode | undefined = undefined;

    if (!!image) {
      imageKey = `${newItemKey}${encodeURIComponent(image!.name)}`;
      imageUrl$ = putObject({
        Bucket: blogger.editorBucket!,
        Key: imageKey,
        Body: image
      }).pipe(
        map(x => (!!x.error ? undefined : imageKey) as string)
      );
      imageItem = {
        name: image.name,
        prefix: imageKey,
        leastNode: true,
        level: parentLevel + (isBlog ? 2 : 1),
        open: false,
      };
    } else {
      imageUrl$ = from([undefined]);
    }

    const createBlog$ = merge(
      imageUrl$.pipe(mergeMap(url => from(templateFiles(url))),
      mergeMap(y => putObject({
          ...{
        Bucket: blogger.editorBucket!,
        Key: `${newItemKey}${y.key}`,
        ContentType: y.type ?? 'application/json'
        }, ...(!y.content 
          ? { ContentLength: 0 } 
          : { Body: JSON.stringify(y.content) })}
      )))
    );

    const items = templateFiles().reduce((acc, x) => {
      const newItem: BlogTreeNode = {
        name: x.key,
        prefix: `${newItemKey}${x.key}`,
        leastNode: true,
        level: parentLevel + (isBlog ? 2 : 1),
        open: false,
      };
      if (acc.length > 0) {
        const curItem = acc[acc.length - 1];
        if (newItem.name.indexOf(curItem!.name) === 0) {
          curItem!.secondaryItems = [...(curItem?.secondaryItems ?? []), newItem.name.substring(curItem!.name.length)];
          return acc;
        } else {
          return [...acc, newItem];
        }
      }
      return [newItem];
    }, (!!imageItem ? [imageItem] : []) as BlogTreeNode[]);

    const node: BlogTreeNode = isBlog ? {
      name: `${blogInfo.urlName}/`,
      prefix: newItemKey,
      leastNode: false,
      level: parentLevel + 1,
      open: true,
      items: items
    } : items[0];
    
    return createBlog$.pipe(
      reduce((acc, x) =>  ({...acc,
        error: acc.error ?? x.error
      }), { node, parent, error: undefined as unknown as any }),
      mergeMap(x => from([...(!x.error ? [
        updateBlogNode({
          newItem: x.node,
          parent: !!parent ? blogLocation : undefined
        }),
        setCurrentBlog({
          blog: undefined,
          content: undefined,
          // parent: parent,
          saved: true
        }),
        setCurrentBlog({
          blog: undefined,
          content: undefined,
          saved: undefined
        })] : [
          setCurrentBlog({
            blog: blogInfo,
            content: content,
            // parent: parent,
            error: x.error
          })
        ]),
      ])),
      startWith(setCurrentBlog({
        blog: blogInfo,
        content: content,
        saving: true
        // parent: parent
      }))    
    );
  }),
);

export const bloggerCreatorEpic = combineEpics(
  blogListRequestsIssuerEpic, 
  createBlogEpic);