import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { VisuallyHiddenInput } from './visually-hidden-input';
import { DetailedHTMLProps, InputHTMLAttributes } from 'react';

export type InputFileUploadProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement> ,HTMLInputElement> & {
  label?: string,
  error?: boolean
}

export default function InputFileUpload({ label, error, ...props }: InputFileUploadProps) {
  return (
    <Button
      component="label"
      role={undefined}
      color='secondary'
      variant="contained"
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
      sx={ !!error ? {borderColor: 'red' } : undefined }
    >
      {label ?? 'Upload file'}
      <VisuallyHiddenInput {...props} type="file" />
    </Button>
  );
}