import { startTransition } from "react";
import { hydrateRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { getAllRoutes } from "routes/all-routes";
import { CommonApp } from "index";
import reportWebVitals from './reportWebVitals';
import { createStore, RootState } from "redux/store";

declare global {
  interface Window {
    __PRELOADED_STATE__?: RootState;
  }
}

const store = createStore(window.__PRELOADED_STATE__);
delete window.__PRELOADED_STATE__;
const allRoutes = getAllRoutes(store);
const router = createBrowserRouter(allRoutes);
const root = document.getElementById('root')!;


startTransition(() => {
  hydrateRoot(
    root,
    <CommonApp store={store}>
      <RouterProvider router={router} />
    </CommonApp>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
