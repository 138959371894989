import { useCallback, useEffect, useState } from "react";
import { ReviewerProps } from "./reviewer.connected";
import { Box, Button } from "@mui/material";
import { useSignedUrl } from "utils/use-signed-url.hook";

export type ImageReviewerProps = ReviewerProps<string> & {
};

function ImageReviewer({ blogger, itemPath }: ImageReviewerProps) {
  const [imageUrl, setImageUrl] = useState<string|undefined>(undefined);

  const getImageSignedUrl = useSignedUrl(blogger?.currentBucket, blogger?.blogsLocation);

  const getImageUrl = useCallback(async () => !!itemPath ? getImageSignedUrl(itemPath) : Promise.resolve(undefined), [itemPath, getImageSignedUrl]);
  const refreshItem = useCallback(async () => {
    const url = await getImageUrl();
    setImageUrl(url);
  }, [getImageUrl]);

  useEffect(() => { refreshItem(); }, [refreshItem]);
  
  return (<>
    <span>Image viewer: {itemPath}</span> <Button onClick={() => refreshItem()} >refresh</Button>
    <Box>
      {!!imageUrl ? <img src={imageUrl} /> : <span>Loading...</span>}
    </Box>
  </>)
}

export default ImageReviewer;