export function decodeJwt(token: string) {
  try {
    // Split the token into parts
    const parts = token.split('.');
    if (parts.length !== 3) {
      throw new Error('The token is invalid');
    }

    // Base64 decode the payload
    const payload = parts[1];
    const decodedPayload = atob(payload.replace(/-/g, '+').replace(/_/g, '/'));

    // Parse the JSON
    const jsonPayload = JSON.parse(decodedPayload);

    return jsonPayload;
  } catch (error) {
    console.error('Failed to decode JWT:', error);
    return null;
  }
}