import './blogs.scss';
import React, { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { BloggerState } from '@model/blogger-state';
import BlogsList from './blogs-list';
import { Divider, Drawer, IconButton, styled, SwipeableDrawer, Typography, useTheme } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { drawerWidth } from 'global-settings';
import { DrawerState } from '@model/ui-state';
import { BlogTreeNode } from '@model/blog-tree-node';
import { ExecuteBatchActionTypes } from 'redux/modules/user/blogger/bloggerSlice';
import { BlogsEditorPath, BlogsReviewerPath } from 'app-constants';

export type BlogsProps = {
  blogger: BloggerState,
  drawer?: DrawerState,
  fullScreen: boolean,
  mountDrawer: (open: boolean, width: number | undefined) => void,
  unmountDrawer: () => void,
  closeDrawer: () => void,
  openDrawer: () => void,
  toggleBlogNode: (node: BlogTreeNode, parent?: BlogTreeNode) => void,
  checkBlogNodes: (item: BlogTreeNode, select: boolean) => void,
  clearBlogNodes: () => void,
  executeBatchAction: (action: ExecuteBatchActionTypes) => void
}

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

function Blogs({blogger, drawer, fullScreen, closeDrawer, mountDrawer, unmountDrawer, toggleBlogNode, checkBlogNodes, clearBlogNodes, executeBatchAction }: BlogsProps) {
  const navigate = useNavigate();  
  const theme = useTheme();
  const location = useLocation();
  const editOrView = useMemo<boolean>(() => location.pathname.startsWith(`/${BlogsEditorPath}/edit/`) || location.pathname.startsWith(`/${BlogsReviewerPath}/view/`),
    [location.pathname]);

  useEffect(() => {
    mountDrawer(!!blogger.mode && !editOrView, drawerWidth);
    return () => unmountDrawer();
  }, [blogger?.mode, editOrView]);

  const handleDrawerClose = () => {
    closeDrawer();
  };

  const open = drawer?.open === true && !fullScreen;

  return (
    <>
    <Drawer
        sx={{
          display: { xs: 'none', md: 'block'},
          width: drawer?.width,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
      <DrawerHeader>
        <Typography sx={{flexGrow: 1}}>Blogs: </Typography>
          {blogger.mode === "editor" && <IconButton aria-label="create new" onClick={() => navigate('new/blog')}>
            <AddCircleOutlineIcon />
          </IconButton>}

        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <BlogsList blogger={blogger} toggleBlogNode={toggleBlogNode} checkBlogNodes={checkBlogNodes} executeBatchAction={executeBatchAction} clearCheckedBlogNodes={clearBlogNodes} />      
      </Drawer>
      <SwipeableDrawer
        sx={{
          display: { xs: 'block', md: 'none'},
          width: { xs: '100%', md: drawer?.width },
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: { xs: '100%', md: drawerWidth },
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
        onClose={function (_: React.SyntheticEvent<{}, Event>): void {
          
        } } onOpen={function (_: React.SyntheticEvent<{}, Event>): void {
          
        } }      >
      <DrawerHeader>
        <Typography sx={{flexGrow: 1}}>Blogs: </Typography>
        <IconButton aria-label="create new" onClick={() => navigate('new/blog')}>
          <AddCircleOutlineIcon />
        </IconButton>

        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
        <BlogsList blogger={blogger} toggleBlogNode={toggleBlogNode} checkBlogNodes={checkBlogNodes} executeBatchAction={executeBatchAction} clearCheckedBlogNodes={clearBlogNodes}
          onNavigate={closeDrawer}
        />      
    </SwipeableDrawer>
    <Outlet />
    </>
);
}

export default Blogs;