// import MarkdownEditor from "./markdown-editor";
import { EditorProps } from "./editor.connected";
import { useCallback, useMemo, useState } from "react";
import { S3ObservableFactory } from "aws/aws-observable";
import { filter, map, tap } from "rxjs";
import { useDebounceEffect } from "utils/debounce-effect.hook";
import { PostInfo } from "@model/post-info";
import moment from "moment";
import MarkdownEditor from './markdown-editor';

const allowedImages = ['.svg', '.png', '.jpg'];

export type PostEditorProps = EditorProps & {
  preloadImages?: boolean,
  customPreview?: (data: string) => Promise<string>
};

function PostEditor({ blogger, data, itemPath, preloadImages, api, fetchItem, uploadItem, setFullScreen, customPreview }: PostEditorProps) {
  const save = useCallback((md: string | undefined) => {
    if (!!md) {
      uploadItem(itemPath!, md, 'text/markdown');
      const metaItemPath = `${itemPath}.meta.json`;

      api.fetchItem(metaItemPath, blogger.currentBucket!).then(metaStr => {
        if (!!metaStr) {
          const meta = JSON.parse(metaStr) as PostInfo;
          uploadItem(metaItemPath, JSON.stringify({ ...meta, date: moment().utc() }), 'application/json');
        }
      })
    }
  }, [uploadItem]);
  
  const refreshItem = useCallback(async () => fetchItem(), [fetchItem]);
  const [imageList, setImageList] = useState<string[] | undefined>(undefined);
  const bucketExists = useMemo(() => !!blogger?.editorBucket || !!blogger?.publishedBucket, [blogger?.editorBucket, blogger?.publishedBucket]);

  const listObjectsV2 = useMemo(() => new S3ObservableFactory().listObjectsV2(), [bucketExists]);
  
  useDebounceEffect(() => {
    if (preloadImages === false) return;

    if (!!itemPath) {
      const slashIndex = itemPath.lastIndexOf('/');
      const rootFolder = slashIndex >= 0 ? itemPath.substring(0, slashIndex) : '';
      const sub = listObjectsV2({
        Bucket: blogger.editorBucket!,
        Prefix: rootFolder
      }).pipe(
        filter(x => !x.error),
        map(x => !x.data?.Contents ? undefined : Array.from(x.data?.Contents!)
          .filter(y => !!allowedImages.find(ext => y.Key?.toLowerCase().endsWith(ext)))
          .map(y => y.Key!)),
        tap(x => {
          setImageList(x?.map(p => rootFolder.length === 0 ? p : p.substring(rootFolder.length + 1)));
        })
      ).subscribe();

      return () => {
        sub.unsubscribe();
        setImageList(undefined);
      }
    }
    return () => {
      setImageList(undefined);
    }
  }, [itemPath, listObjectsV2, preloadImages]);

  return (<MarkdownEditor bucket={blogger.editorBucket!} initialValue={data ?? ''} customImages={imageList} itemPath={itemPath} save={save} refreshItem={refreshItem} onToggleFullScreen={setFullScreen} customPreview={customPreview} />);
}

export default PostEditor;