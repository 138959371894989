import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { BlogMenuPopupAction } from './blog-menu.popup';
import { Typography } from '@mui/material';

export type BlogMenuAddPopupProps = {
  anchorElement: null | HTMLElement,
  open: boolean,
  onClose?: (action: BlogMenuPopupAction) => void
}

export default function BlogMenuAddPopup({anchorElement, open, onClose}: BlogMenuAddPopupProps) {
  const handleClose = (action: BlogMenuPopupAction) => {
    if (!!onClose) onClose(action);
  };

  return (
    <Menu
      anchorEl={anchorElement}
      id="blog-menu-add"
      open={open}
      onClose={_ => handleClose(BlogMenuPopupAction.none)}
      onClick={_ => handleClose(BlogMenuPopupAction.none)}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <MenuItem onClick={_ => handleClose(BlogMenuPopupAction.addDrawIo)}>
      <Typography variant='body2'>Drawio Diagram</Typography>
      </MenuItem>
      <MenuItem onClick={_ => handleClose(BlogMenuPopupAction.addPlantUml)}>
      <Typography variant='body2'>Plant Uml</Typography>
      </MenuItem>
      <MenuItem onClick={_ => handleClose(BlogMenuPopupAction.addPost)}>
      <Typography variant='body2'>Post</Typography>
      </MenuItem>
      <MenuItem onClick={_ => handleClose(BlogMenuPopupAction.addBlog)}>
        <Typography variant='body2'>Blog</Typography>
      </MenuItem>
   </Menu>
  );
}