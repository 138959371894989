import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectDrawer, selectFullScreen } from 'redux/modules/app/selectors';
import { mountDrawer, closeDrawer, openDrawer, unmountDrawer } from 'redux/modules/app/uiStateSlice';
import { selectBlogger, selectUser } from 'redux/modules/user/selectors';

import Blogs from './blogs';
import { checkBlogNode, toggleBlogNode, executeBatchAction, ExecuteBatchActionTypes, clearBlogNodes } from 'redux/modules/user/blogger/bloggerSlice';
import { BlogTreeNode } from '@model/blog-tree-node';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';

function ConnectedBlogs() {
  const blogger = useAppSelector(selectBlogger);
  const user = useAppSelector(selectUser);
  const drawer = useAppSelector(selectDrawer);
  const fullScreen = useAppSelector(selectFullScreen);
  const dispatch = useAppDispatch();

  const dispatchOpenDrawer = useCallback(() => dispatch(openDrawer()), [dispatch]);
  const dispatchCloseDrawer = useCallback(() => dispatch(closeDrawer()), [dispatch]);
  const dispatchUnmountDrawer = useCallback(() => dispatch(unmountDrawer()), [dispatch]);
  const dispatchMountDrawer = useCallback((open: boolean, width: number | undefined) => dispatch(mountDrawer({ open, width })), [dispatch]);
  const dispatchToggleBlogNode = useCallback((blogNode: BlogTreeNode, parent: BlogTreeNode | undefined) => dispatch(toggleBlogNode({ blogItem: blogNode, itemParent: parent! })), [dispatch]);
  const dispatchCheckBlogNodes = useCallback((blogNode: BlogTreeNode, selected: boolean) => dispatch(checkBlogNode({ blogItem: blogNode, selected })), [dispatch]);
  const dispatchClearChekedBlogNodes = useCallback(() => dispatch(clearBlogNodes()), [dispatch]);
  const dispatchExecuteBatchAction = useCallback((batchAction: ExecuteBatchActionTypes) => dispatch(executeBatchAction({ batchAction })), [dispatch]);
  
  return (
    user?.authenticated ? <Blogs blogger={blogger} drawer={drawer} fullScreen={fullScreen}
      openDrawer={dispatchOpenDrawer}
      closeDrawer={dispatchCloseDrawer}
      unmountDrawer={dispatchUnmountDrawer}
      mountDrawer={dispatchMountDrawer}
      toggleBlogNode={dispatchToggleBlogNode}
      checkBlogNodes={dispatchCheckBlogNodes}
      executeBatchAction={dispatchExecuteBatchAction}
      clearBlogNodes={dispatchClearChekedBlogNodes}
    /> : <span>Access Denied go to <Link to="/">Home Page</Link></span>
  );
}

export default ConnectedBlogs;
