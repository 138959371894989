/* eslint-disable */
import { awsRegion } from './aws-region';
import { awsPool, authDomain, websiteDomain } from 'aws-website-config';

const awsmobile = {
    ...awsPool,
    "aws_project_region": `${awsRegion}`,
    "aws_cognito_region": `${awsRegion}`,
    "oauth": {
        "domain": authDomain,
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": `http://localhost:3000/signed,https://${websiteDomain}/signed`,
        "redirectSignOut": `http://localhost:3000/sidnedout,https://${websiteDomain}/sidnedout`,
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "AMAZON"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },  
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
