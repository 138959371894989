import { ReactElement, useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { MainContent } from "./main-content";
import { fullScreen } from "redux/modules/app/uiStateSlice";
import { selectCachedPosts, selectFullScreen } from "redux/modules/app/selectors";
import { useDebounceEffect } from "utils/debounce-effect.hook";
import { fetchPostData, PostData } from "data-providers/post.data-provider";

export type PostViewerProps = {
  post: PostData | null | undefined,
  itemPath: string | undefined,
  fullScreen: boolean,
  setFullScreen: (fullScreen: boolean) => void
}

export type ConnectedPostViewerProps = {
  autoloading?: boolean,
  children: (props: PostViewerProps) => ReactElement<any, any>,
}

export function ConnectedPostViewer({children, autoloading}: ConnectedPostViewerProps) {
  const fullScreenState = useAppSelector(selectFullScreen);

  const dispatch = useAppDispatch();
  const cachedPosts = useAppSelector(selectCachedPosts)
  const params = useLocation();
  const itemId = useMemo(() => 
    params.pathname, [params.pathname]);
  const [data, setData] = useState<PostData|null|undefined>(cachedPosts[itemId]);
  

  const [fetchUserItemFlag, setFetchUserItemFlag] = useState(false);

  const fetchItem = useCallback(fetchPostData, []);

  const fetchUserItem = useCallback(async () => {
    if (!!itemId) {
      const postData = await fetchItem(itemId!)
      setData(postData);
      return postData;
    } else {
      setFetchUserItemFlag(true);
    }
    return null;
  }, [itemId, fetchItem]);

  useDebounceEffect(() => {
    if ((fetchUserItemFlag || autoloading !== false) && !data) {
      fetchUserItem();
    }
  }, [itemId, fetchUserItemFlag, autoloading, data, fetchUserItem]);

  const setFullScreen = useCallback((fs: boolean) => dispatch(fullScreen(fs)), [dispatch]);

  return (
    <>
      <MainContent fullScreen={fullScreenState} fullWidth noMargin title={undefined}>
        {children({ post: data, itemPath: itemId, fullScreen: fullScreenState, setFullScreen })}
      </MainContent>
    </>);
}