import { ConnectedReviewer } from 'components/reviewer.connected';
import PostReviewer from 'components/post-reviewer';
import ImageReviewer from 'components/image-reviewer';
import JsonViewer from 'components/json-viewer';

export const reviewerRoutes = import.meta.env.SSR ? [] : [
  {
    path: "view/posts/*",
    element: <ConnectedReviewer>{props => <PostReviewer {...props} />}</ConnectedReviewer>,
  },
  {
    path: "view/meta/*",
    element: <ConnectedReviewer>{props => <JsonViewer {...props} />}</ConnectedReviewer>,
  },
  {
    path: "view/json/*",
    element: <ConnectedReviewer>{props => <JsonViewer {...props} />}</ConnectedReviewer>,
  },
  {
    path: "view/:editorType/*",
    element: <ConnectedReviewer autoloading={false}>{props => <ImageReviewer {...props} />}</ConnectedReviewer>,
  },
];