import './home-page.scss';
import PostCard from "./post-card";
import { Stack } from "@mui/material";
import { MainPage } from "@model/main-page";
import { useCallback, useMemo } from 'react';
import { PostInfo } from '@model/post-info';
import { useNavigate } from 'react-router-dom';
import { BlogsReviewerPath } from 'app-constants';
import { TitleMedia } from './title-media';

export type HomePageProps = MainPage & {
  isBlogger: boolean,
}

function HomePage({ posts, blogs, isBlogger }: HomePageProps) {
  const blog = useMemo(() => blogs.length > 0 ? blogs[0] : undefined, [blogs]);
  const nav = useNavigate();
  const navigate = useCallback((post: PostInfo) => { 
    nav(isBlogger ? `/${BlogsReviewerPath}/view/posts${post.fullUrl}.md` : post.fullUrl);
  }, [nav, isBlogger]);

  return (
    <div>
      <Stack id="home-page-container" direction="column" spacing={2}>
        <TitleMedia blog={blog} isBlogger={isBlogger} />
        <Stack id="post-cards" direction={{ xs: 'column', md: 'row' }} flexWrap={'wrap'} spacing={0} justifyContent="start">
          {posts?.map(x => <PostCard key={x.fullUrl} post={x} spacing={1} onNavigate={navigate} />)}
        </Stack>
      </Stack>
    </div>
  )
}

export default HomePage;