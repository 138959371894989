import { UserState } from "@model/user-state";
import { Button, Typography } from "@mui/material";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

export type UserProfileProps = {
  user: UserState,
  deleteUser: () => void
}

export function UserProfile({ user, deleteUser }: UserProfileProps) {
  return (<Card sx={{ minWidth: 275 }}>
    <CardContent>
      <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
        Profile
      </Typography>
      <Typography variant="h5" component="div">
        {user.displayName}
      </Typography>
    </CardContent>
    {user?.authenticated === true && <CardActions>
      <Button size="small" onClick={deleteUser}>Delete User</Button>
    </CardActions>}
  </Card>);
}