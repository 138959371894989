import { useMemo } from "react";
import { Avatar, Box, Divider, IconButton, Link, Stack, SxProps, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { toHtmlConverter } from "./supportedLanguages";
import 'highlight.js/styles/github-dark.css';
import { PostViewerProps } from "./post-viewer.connected";
import { replaceImageUrls } from "utils/pre-signed-url-converter";
import './post-viewer.scss';
import moment from "moment";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import { DocumentMeta } from "./document-meta";
import { useMobileDevice } from "utils/mobile-device-detection";
import { TitleMedia } from "./title-media";
import { websiteDomain } from "aws-website-config";
import { metaToOpenGraphAttributes } from "utils/open-grapth";
import { OpenGraphAttributes } from "@model/open-graph";

function parseHtml(md: string): string {
  return toHtmlConverter.parse(md) as string;
}

type SocialMediaShareButtonsProps = {
  buttonLinks: any,
  currentPageUrl: string
}

function SocialMediaShareButtons({ buttonLinks, currentPageUrl }: SocialMediaShareButtonsProps) {
  if (!!import.meta.env.SSR) return null;

  return (<><IconButton aria-label="Share on X" target="_blank"
      href={buttonLinks!.twitter}
      data-href={currentPageUrl}
      data-layout="" data-size=""
      sx={{ color: 'black' }}>
      <XIcon sx={{ fontSize: "17px" }} />
    </IconButton>
    <IconButton aria-label="Share on Facebook" target="_blank"
      href={buttonLinks!.faceBook}
      data-href={currentPageUrl}
      data-layout="" data-size=""
      sx={{ color: 'rgb(25, 118, 210)' }}>
      <FacebookIcon sx={{ fontSize: "24px" }} />
    </IconButton>
    <IconButton aria-label="Share on LinkedIn"
      sx={{ color: 'rgb(25, 118, 210)' }}
      target="_blank" href={buttonLinks!.linkedIn} >
      <LinkedInIcon sx={{ fontSize: "25px" }} />
    </IconButton>
    </>
  );
}
export function PostViewer({ post, itemPath }: PostViewerProps) {
  const htmlContent = useMemo(() => {
    if (!post) return null;
    const prefix = itemPath!.substring(0, itemPath!.lastIndexOf("/"));
    const newContent = replaceImageUrls(post.content!, url => `/content${prefix}/${url}`) as string;
    return parseHtml(newContent);
  }, [post]);
  
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  
  const currentPageUrl = useMemo(() => `https://${websiteDomain}${itemPath}`, [itemPath]);
  const contentHttpFullUrl = useMemo(() => `https://${websiteDomain}`, []);


  const og = useMemo<OpenGraphAttributes | undefined>(() => {
    if (!!post?.meta) {
      const ogr: OpenGraphAttributes = metaToOpenGraphAttributes(post.meta!, contentHttpFullUrl, currentPageUrl)
      return ogr;
    }
    return undefined;
  }, [post?.meta, currentPageUrl]);
 
  const isMobile = useMobileDevice();

  const headerStyle: SxProps<Theme> = useMemo(() => smUp ? { mt: '1.19em', mb: '1.19em', fontSize: "42px", fontWeight: 700 } : { mt: '0.19em', mb: '0.19em', fontSize: "30px", fontWeight: 600 }, [smUp]);

  const buttonLinks = useMemo(() => {
    if (!!post?.meta) {
      return {
        linkedIn: isMobile ? `linkedin://shareArticle?mini=true&url=${encodeURIComponent(currentPageUrl)}` : `https://www.linkedin.com/share/share-offsite/?url=${encodeURIComponent(currentPageUrl)}&title=${encodeURIComponent(post.meta.title)}&summary=${encodeURIComponent(post.meta.description)}&source=LinkedIn`,
        faceBook: isMobile ? `fb://facewebmodal/f?href=https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentPageUrl)}` : `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentPageUrl)}`,
        twitter: isMobile ? `twitter://post?url=${encodeURIComponent(currentPageUrl)}` : `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentPageUrl)}`
      }
    }

    return null;
  }, [isMobile, post?.meta]);

  return (<article>
    <section>
    <Box>
      {!!post?.meta && <>
        <DocumentMeta title={post.meta.title} og={ og } />
        <Typography variant="h1" sx={ headerStyle }>{post.meta.title}</Typography>
        <Stack direction="row" spacing={2}>
          <Avatar alt={post.meta.author} src="/content/avatar.jpg" sx={{ width: 56, height: 56 }} />
          <Stack direction="column">
            <Stack direction="row">{!!post.meta.author && <><Typography variant="body1">{post.meta.author}</Typography><span className="dot"><span>.</span></span></>}<Link target="_blank" href="https://www.linkedin.com/in/anton-volodko-240318b9/">Follow</Link></Stack>
            <Stack direction="row" sx={{ color: 'GrayText' }}><Typography variant="subtitle2">{moment(post.meta.date).format('MMM DD, YYYY')}</Typography></Stack>
          </Stack>
        </Stack>
        <Stack sx={{mt: 3, mb: 3 }}>
          <Divider />
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" spacing={1}>
            </Stack>
              <Stack direction="row" spacing={0}>
                <SocialMediaShareButtons buttonLinks={buttonLinks} currentPageUrl={currentPageUrl} />  
              </Stack>
          </Stack>
          <Divider />
        </Stack>
        </>}
        <TitleMedia blog={post?.meta} isBlogger={false} />
        {!!htmlContent ? <div dangerouslySetInnerHTML={{ __html: htmlContent }} /> : <span>loading...</span>}
      </Box>
      </section>
      </article>)
} 

