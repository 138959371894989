import * as toolkit from '@reduxjs/toolkit';
import { BloggerState, ItemEditingState } from '@model/blogger-state';
import { BlogTreeNode } from '@model/blog-tree-node';
import { BlogInfo } from '@model/blog-info';
import { FileManager } from 'utils/file-manager';

export const initialState: BloggerState = {
  editorBucket: undefined,
  reviewerBucket: undefined,
  publishedBucket: undefined,
  mode: undefined,
  currentBucket: undefined,
  toReviewLambdaName: undefined,
  publishLambdaName: undefined,
  blogsLocation: '',
  blogs: []  
};

export type ToggleBlogNodePayload = {
  blogItem: BlogTreeNode,
  itemParent: BlogTreeNode
}

export type CheckBlogNodePayload = {
  blogItem: BlogTreeNode,
  selected: boolean
}

export type UpdateBlogNodePayload = {
  newItem: BlogTreeNode,
  rootItems?: BlogTreeNode[],
  parent?: string
}

export type CreateBlogPayload = {
  blogInfo: BlogInfo,
  parent?: string,
  content?: any,
  image?: File
}

export type SetCurrentBlogPayload = ItemEditingState & {
}

export type ExecuteBatchActionTypes = 'to-review' | 'publish' | 'remove';
export type ExecuteBatchActionPayload = {
  batchAction: ExecuteBatchActionTypes
}

/// workaround to change hierarhical structure in the redux-toolkit implementation which internally uses the https://immerjs.github.io/immer/installation
function replaceNode(curItem: BlogTreeNode, newItem: BlogTreeNode, parentPrefix?: string): BlogTreeNode {
  if (curItem?.name === newItem?.name && curItem?.level === newItem?.level) return newItem;
  for (let itemIndex = 0; itemIndex < (curItem?.items?.length ?? 0); itemIndex++) {
    const childItem = curItem?.items![itemIndex];
    const replacedItem = replaceNode(childItem!, newItem, parentPrefix)
    if (!!replacedItem) {
      curItem!.items![itemIndex] = replacedItem;
      return curItem;
    } 
  }
  if (!curItem?.leastNode && curItem?.prefix === parentPrefix) {
    const parentOfItem = curItem!.items?.find(x => newItem?.name.startsWith(x!.name));
    if (!!parentOfItem) {
      const suffix = newItem?.name.substring(parentOfItem.name.length);
      if (!parentOfItem.secondaryItems?.find(x => x === suffix)) {
        parentOfItem.secondaryItems = [...(parentOfItem.secondaryItems ?? []), suffix!];
      }
    } else {
      curItem!.items?.splice(0, 0, newItem);
    }
    return curItem;
  }

  return null as BlogTreeNode;
}

function removeCheckeNodes(nodes: BlogTreeNode[]): BlogTreeNode[] {
  for (let index = 0; index < (nodes?.length ?? 0); index++) {
    const node = nodes[index];
    if (node?.checked !== true && node?.leastNode !== true) {
      const newItems = removeCheckeNodes(node!.items!);
      node!.items = newItems;
    }
  }
  return nodes?.filter(x => x?.checked !== true || !FileManager.canDelete(x.name));
}

function findNode(nodes: BlogTreeNode[], fnode: BlogTreeNode): BlogTreeNode {
  for (let index = 0; index < (nodes?.length ?? 0); index++) {
    const node = nodes[index];
    if (node?.name === fnode?.name && node?.prefix === fnode?.prefix) {
      return node;
    } else if (!(node?.leastNode === true && (node?.items?.length ?? 0) > 0)) {
      const foundNode = findNode(node!.items!, fnode);
      if (!!foundNode) return foundNode;
    }
  }
  return null;
}

function checkNode(curItem: BlogTreeNode, selected: boolean): void {
  curItem!.checked = selected;
  for (let itemIndex = 0; itemIndex < (curItem?.items?.length ?? 0); itemIndex++) {
    const childItem = curItem!.items![itemIndex];
    curItem!.items![itemIndex] = {...childItem!, checked: selected};
    checkNode(childItem!, selected);
  }
}

export const bloggerSlice = toolkit.createSlice({
  name: 'blogger',
  initialState,
  reducers: {
    setBlogger: (_: BloggerState, action: toolkit.PayloadAction<BloggerState>) => {
      if (!!action.payload) {
        return action.payload;
      } else {
        return initialState;
      }
    },
    toggleBlogNode: (state: BloggerState, _: toolkit.PayloadAction<ToggleBlogNodePayload>) => state,
    checkBlogNode: (state: BloggerState, { payload }: toolkit.PayloadAction<CheckBlogNodePayload>) => {
      const { blogItem, selected } = payload;
      const node = findNode(state.blogs, blogItem);
      checkNode(node, selected);
      return state;
    },
    clearBlogNodes: (state: BloggerState, { }: toolkit.PayloadAction<void>) => {
      const checkedNodes: BlogTreeNode[] = [];
      getCheckedNodes(state.blogs, checkedNodes);
      checkedNodes.forEach(x => x!.checked = false);
      return state;
    },
    executeBatchAction: (state: BloggerState, { } : toolkit.PayloadAction<ExecuteBatchActionPayload>) => state,
    removeCheckedNodesAction: (state: BloggerState, { }: toolkit.PayloadAction<void>) => {
      state.blogs = removeCheckeNodes(state.blogs);
      return state;
    },
    updateBlogNode: (state: BloggerState, action: toolkit.PayloadAction<UpdateBlogNodePayload>) => 
    {      
      const { newItem, rootItems, parent } = action.payload;
      if (rootItems) {
        return {...state, blogs: rootItems};
      }

      const rootBlogs = {items: state.blogs, open: false, name: 'temp', leastNode: false, prefix: '' } as BlogTreeNode;
      const replaced = replaceNode(rootBlogs, newItem, parent);
      state.blogs = [...(!replaced ? [newItem] : []), ...(rootBlogs!.items ?? [])];
      return state;
    },
    createBlog: (state: BloggerState, _: toolkit.PayloadAction<CreateBlogPayload>) => state,
    createPost: (state: BloggerState, _: toolkit.PayloadAction<CreateBlogPayload>) => state,
    setCurrentBlog: (state: BloggerState, action: toolkit.PayloadAction<SetCurrentBlogPayload>) => {
      state.currentItem = {...action.payload};
      return state;
    },
    setEditorMode: (state: BloggerState) => {
      state.mode = "editor";
      state.blogs = [];
      state.currentBucket = state.editorBucket;
      return state;
    },
    setReviewerMode: (state: BloggerState) => {
      state.mode = "reviewer";
      state.blogs = [];
      state.currentBucket = state.reviewerBucket;
      return state;
    },
  }
})

export const { setBlogger, toggleBlogNode, updateBlogNode, createBlog, setCurrentBlog, checkBlogNode, clearBlogNodes, executeBatchAction, removeCheckedNodesAction, setEditorMode, setReviewerMode, createPost } = bloggerSlice.actions;
export default bloggerSlice.reducer as toolkit.Reducer<BloggerState>;

export function getCheckedNodes(nodes: BlogTreeNode[], foundNodes: BlogTreeNode[]): void {
  for (let index = 0; index < (nodes?.length ?? 0); index++) {
    const node = nodes[index];
    if (node?.checked === true /*&& node?.leastNode === true */) {
      foundNodes.push(node);
    } else {
      if (node?.leastNode !== true) {
        getCheckedNodes(node?.items ?? [], foundNodes);
      }
    }
  }
}
