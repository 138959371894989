import { BlogInfo } from "@model/blog-info"
import { Box } from "@mui/material"
import './title-media.scss';

export type TitleMediaProps = {
  blog?: BlogInfo | null,
  isBlogger: boolean
}

export function TitleMedia({ blog }: TitleMediaProps) {
  return (blog && <Box id="title-media" width={{ xs: '100%' }} component="div" sx={{
    aspectRatio: '16 / 9',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'whitesmoke',
    borderRadius: '12px',
    flexDirection: 'column'
  }}>
      <Box alignSelf="center" height="100%" width="100%">
      {(blog.video?.type === 'youtube' && !!blog.video.url)
        ? <iframe height="100%" style={ {aspectRatio: '16 / 9'} } src={blog.video.url} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        : <>
          <figure className="paragraph-image__container">
            <picture>
              <img loading="eager" src={blog.titleImage} className="paragraph-image" alt={blog.description} />
            </picture>
            {/* <figcaption></figcaption> */}
          </figure>
        </>}
      </Box>
    </Box>
  )
}