import { createTheme, unstable_createMuiStrictModeTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

const theme = unstable_createMuiStrictModeTheme();

const blogTheme = createTheme({
  typography: {    
    fontFamily: `'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif`,
    // "fontSize": 14,
    // "fontWeightLight": 300,
    // "fontWeightRegular": 400,
    // "fontWeightMedium": 500
  },
  palette: {
    primary: {
      main: '#483D8B',
    },
  },
});

export const mainTheme = deepmerge(theme, blogTheme);