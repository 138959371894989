import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import bash from 'highlight.js/lib/languages/bash';
import dockerfile from 'highlight.js/lib/languages/dockerfile';
import csharp from 'highlight.js/lib/languages/csharp';
import typescript from 'highlight.js/lib/languages/typescript';
import json from 'highlight.js/lib/languages/json';
import html from 'highlight.js/lib/languages/xml';
import css from 'highlight.js/lib/languages/css';
import { Marked } from 'marked';

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('bash', bash);
hljs.registerLanguage('docker', dockerfile);
hljs.registerLanguage('csharp', csharp);
hljs.registerLanguage('typescript', typescript);
hljs.registerLanguage('json', json);
hljs.registerLanguage('html', html);
hljs.registerLanguage('css', css);

export const supportedMarkdownLanguages = hljs.listLanguages();

export const toHtmlConverter = new Marked();

toHtmlConverter.use({
  breaks: true,
  renderer: {
    code: ((code: string, language: string) => {      
      if (language && hljs.getLanguage(language)) {
        const html = hljs.highlight(code, { language }).value;
        return `<pre>
                    <code class="hljs">${html}</code>
                </pre>`;
      } else {
        return hljs.highlightAuto(code).value;
      }
    }) as unknown as any,
  }
});
