import { copyToClipboard } from "utils/copy-text";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { IconButton } from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import { useCallback, useState } from "react";

export type AttachedCopyButtonProps = {
    getTextToCopy: () => string
}

export function AttachedCopyButton({ getTextToCopy }: AttachedCopyButtonProps) {
  const [copied, setCopied] = useState<"done" | "failed" | false>(false);
  const copyText = useCallback(async () => {
    try {
      await copyToClipboard(getTextToCopy());
      setCopied("done");
    } catch {
      setCopied("failed");
    }
    setTimeout(() => setCopied(false), 500);
  }, [getTextToCopy]);
  
  return (<><div style={{ width: '100%', height: '100%', position: "absolute", top: 0, left: 0, pointerEvents: 'none' }}>
    <IconButton style={{ position: "absolute", right: 0, top: 0, pointerEvents: 'auto' }} onClick={() => copyText()}>
      {copied === false ? <ContentCopyIcon htmlColor="white" />
        : (copied === "done" ? <DoneIcon htmlColor="green"/> : <ErrorIcon htmlColor="red"/> )}

    </IconButton></div></>);
}