import { EditorProps } from "./editor.connected";
import { useCallback } from "react";
import { compress } from "utils/plant-uml-encoder";
import PostEditor from "./post-editor";

export type PlantUmlEditorProps = EditorProps & {
  
}

function PlantUmlEditor({ uploadItem, ...props }: PlantUmlEditorProps) {
  const savePlantUml = useCallback(async (itemPath: string, data: string | Buffer) => {
    const toSave = data as string;
    await uploadItem(itemPath!, toSave, 'text/plain');
    const svgData = await fetchPlantUmlSvg(toSave);
    return uploadItem(`${itemPath}.svg`, svgData, 'image/svg+xml');
  }, []);

  return (<>
    <PostEditor {...props} uploadItem={savePlantUml} customPreview={fetchPlantUmlSvg} preloadImages={false} />
  </>)
}

export default PlantUmlEditor;

async function fetchPlantUmlSvg(data: string): Promise<string> {
  const umlEncoded = compress(data ?? '');
  const result = await fetch(`https://www.plantuml.com/plantuml/svg/${umlEncoded}`);
  const svg = await result.text();
  return svg;
}