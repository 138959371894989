import { useParams } from "react-router-dom";
import { useMemo, useState } from "react";
import { MainContent } from "./main-content";
import { Box, Button, IconButton, LinearProgress, List, ListItem, ListItemText } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { BloggerState } from "@model/blogger-state";
import ReportIcon from '@mui/icons-material/Report';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Upload } from "@aws-sdk/lib-storage";
import { createS3Client } from "utils/s3-client.factory";

export type BlogFileUploadProps = {
  blogger: BloggerState
};

type UploadFileStatus = {
  cancel: () => void;
  file: File,
  total?: number,
  loaded?: number,
  inProgress: boolean,
  completed: boolean;
  failed: boolean;
}

export function BlogFileUpload({ blogger }: BlogFileUploadProps) {
  const params = useParams();
  const parentBlogId = useMemo(() => params['*'], [params]);
  const [files, selectFiles] = useState<UploadFileStatus[]>([]);

  async function upload() {
    for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
      const file = files[fileIndex];
      if (!file.inProgress && !file.completed) {
        await uploadFile(file);
      }
    }
  }

  async function uploadFile(fileStatus: UploadFileStatus) {
    const { file } = fileStatus;
    const safeFileName = file.name;
    var upload = new Upload({
      client: createS3Client(),
      params: {
        Bucket: blogger.editorBucket!,
        Key: `${parentBlogId}/${safeFileName}`,
        Body: file
      }
    });
    fileStatus.cancel = upload.abort.bind(upload);
    fileStatus.inProgress = true;
    selectFiles([...files]);
    upload.on('httpUploadProgress', (progress) => {
      fileStatus.loaded = progress.loaded;
      fileStatus.total = progress.total;
      selectFiles([...files]);
    });
    try {
      await upload.done();
    } catch(err) {
      console.error(err);
      fileStatus.failed = true;
    }
    fileStatus.inProgress = false;
    fileStatus.completed = true;
    selectFiles([...files]);
  }

  return (<MainContent title="Upload Files">
    <Button component="label" variant="outlined" >Add File...
    <input type="file" multiple hidden onChange={(e) => {
      const newFiles = [...files.filter(x => x.completed === false), 
        ...Array.from(e.target.files ?? []).map<UploadFileStatus>(x => ({
          file: x,
          completed: false,
          failed: false,
          inProgress: false,
          loaded: 0,
          total: x.size,
          cancel: () => console.warn('Upload cancel is not implemented')
        }))];
      selectFiles(newFiles);
    }} />
    </Button> {!!parentBlogId && <span>to {parentBlogId}</span>}
    {!!files && files.length > 0 &&
    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <nav aria-label="main mailbox folders">
        <List>
          {files.map(x => 
          <ListItem key={x.file.name} disablePadding>
              <ListItemText primary={x.file.name} 
              secondary={x.inProgress && !!x.loaded && !!x.total
                ? <LinearProgress variant="determinate" value={x.loaded/x.total*100} />
                : <span>{x.file.size / 1000.0} KB</span>}/>
                {x.completed 
                ? <>{x.failed ? <ReportIcon htmlColor="red" /> : <CheckCircleIcon htmlColor="green" />}</>
                :<IconButton onClick={() => {
                if (x.inProgress) {
                  x.cancel();
                }
                const newFiles = files.filter(f => f != x);
                selectFiles(newFiles);
              }}><ClearIcon/></IconButton>}
          </ListItem>
          )}
        </List>
      </nav>
      <Button variant="contained" onClick={_ => upload()}>Upload</Button>
    </Box>
    }
 </MainContent>);
}