import { PayloadAction } from "@reduxjs/toolkit";
import { combineEpics, Epic, ofType } from "redux-observable";
import { asyncScheduler, from, interval, ReplaySubject, scheduled } from 'rxjs';
import { filter, map, mergeWith, switchMap, tap } from "rxjs/operators";
import { deleteUser, setUser, signin, signout } from "./userSlice";
import { UserData } from "@model/user-state";
import configureAws from 'aws-configurator';
import { authDomain } from "aws-website-config";
import { generateCodeVerifier } from "utils/oauth/codeVerifier";
import { handleAuthFlow, refreshToken, startSignInFlow } from "utils/oauth/flow";
import { RootState } from "redux/store";
import { DeleteUserCommandInput } from '@aws-sdk/client-cognito-identity-provider';
import { getSession } from "utils/oauth/session";
import { CognitoIdentityServiceFactory } from "aws/aws-observable";

const authSubject = new ReplaySubject<UserData>(1);
const config = configureAws();

if (!import.meta.env.SSR) {
  handleAuthFlow(authSubject);
}

const userEpic: Epic<PayloadAction<any>, PayloadAction<any>, RootState> = action$ => action$.pipe(
  filter(_ => false),
  mergeWith(authSubject.asObservable()),
  map(x => setUser(x))
)

const refreshTokenEpic: Epic<PayloadAction<any>, PayloadAction<any>, RootState> = action$ => action$.pipe(
  filter(_ => false),
  mergeWith(authSubject.asObservable()),
  switchMap((ud: UserData) => {
    return ud && ud.signInUserSession.idToken ? interval((ud.signInUserSession.expires_in * 1000) - 5000) : scheduled([], asyncScheduler);
  }),
  tap(() => {
    refreshToken();
  }),
  filter(() => false),
  map(() => undefined as unknown as PayloadAction<any>)
)

const signinEpic: Epic<PayloadAction<any>, PayloadAction<any>, RootState> = action$ => action$.pipe(
  filter(action => action.type === signin.type),
  switchMap(_ => {
    return from(generateCodeVerifier(128)).pipe(switchMap(x => from(x.toCodeChallenge()).pipe(map(codeChallenge => ({...x, codeChallenge})))));
  }),
  map(({ value, method, codeChallenge }) => {
    startSignInFlow(value, method, codeChallenge);
    return undefined as unknown as PayloadAction;
  }),
  filter(_ => false)
);

const signoutEpic: Epic<PayloadAction<any>, PayloadAction<any>, RootState> = action$ => action$.pipe(
  filter(action => action.type === signout.type),
  tap(_ => {
    if (!import.meta.env.SSR) {
      window.location.href = `https://${authDomain}/logout?client_id=${config.aws_user_pools_web_client_id}&logout_uri=${encodeURIComponent(config.oauth.redirectSignOut)}`;
    }
  }),
  filter(_ => false)
)

const deleteUserEpic: Epic<PayloadAction<any>, PayloadAction<any>, RootState> = action$ => action$.pipe(
  ofType(deleteUser.type),
  switchMap(_ => {
    const session = getSession();
    // Create the required request parameter
    const params: DeleteUserCommandInput = {
      AccessToken: session.access_token
    };

    const factory = new CognitoIdentityServiceFactory();
    const deleteUser$ = factory.deleteuser();


    return deleteUser$(params).pipe(map(({error}) => {
      if (error) {
        alert("User hasn't been delete, try again.");
        console.error(error);
        return null as unknown as PayloadAction<any>;
      }
      return signout();
    }));    
  }),
  filter(x => !!x)
)

export const authenticationEpic = combineEpics(signinEpic, signoutEpic, userEpic, refreshTokenEpic, deleteUserEpic);


