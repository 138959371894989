import { useCallback, useEffect, useRef, useState } from "react";
import "easymde/dist/easymde.min.css";
import './markdown-editor.scss';
import { preloadImages } from "utils/pre-signed-url-converter";
import { CopyCodePortal } from "./copy-code.portal";
import { supportedMarkdownLanguages, toHtmlConverter } from "./supportedLanguages";
import 'highlight.js/styles/github-dark.css';

let useToolbar: (save: (data: string | undefined) => void, refreshItem: () => Promise<string | null>, customImages?: string[], supportedLanguages?: string[], customPreview?: (data: string) => Promise<string>) => any;
if (!import.meta.env.SSR) {
  useToolbar = () => { };
  import("./easymde/markdown-editor.buttons").then(pkg => {
    useToolbar = pkg.useToolbar;
  });
}

export type MarkdownEditorProps = {
  bucket: string,
  initialValue: string,
  itemPath: string | undefined,
  customImages?: string[],
  save: (data: string | undefined) => void,
  refreshItem: () => Promise<string | null>,
  onToggleFullScreen?: (fullScreen: boolean) => void,
  customPreview?: (data: string) => Promise<string>
};

export default function MarkdownEditor({ bucket, initialValue, itemPath, customImages, save, refreshItem, onToggleFullScreen, customPreview }: MarkdownEditorProps) {
  if (!import.meta.env.SSR) {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const [simplemde, setSimpleMde] = useState<EasyMDE | undefined>(undefined);
    const [activePreview, setActivePreview] = useState(false);
    const [EasyMDE, setEasyMDE] = useState<any | undefined>(undefined);
  
    useEffect(() => {
      import("easymde").then(x => setEasyMDE(x));
    }, [])
    const autosaveStatus = useRef<HTMLElement>();
  
    const autosaveHandler = useCallback(() => {
      if (!!simplemde && !!autosaveStatus.current) {
        localStorage.setItem(`smde_${itemPath}`, simplemde.value());
        const date = new Date();
        autosaveStatus.current.innerText = `Autosaved on ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
      }
    }, [itemPath, simplemde]);

    const loadStoredValueHandler = useCallback(() => {
      if (!!simplemde) {
        simplemde.value(initialValue);
        const savedValue = localStorage.getItem(`smde_${itemPath}`);
        if (!!savedValue) {
          simplemde.value(savedValue);
          if (!!autosaveStatus?.current) autosaveStatus.current.innerText = 'Autosaved Value loaded'
        }
      }
    }, [itemPath, simplemde, initialValue]);

    const onChange = useCallback((/*value: string*/) => {
      // it doesn't work as easy mde doesn't provide any on change callbacks.
      // easy mde periodically dump the content to the textarea.value
      // console.log(value);
      //simplemde.value(value.substring(0, value.length - 1));
    }, [simplemde]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const toolbar = useToolbar(save, refreshItem, customImages, supportedMarkdownLanguages, customPreview);

    useEffect(() => {
      if (!!textareaRef.current && !!itemPath && !!EasyMDE) {
        const easyMde = new EasyMDE.default({
          onToggleFullScreen,
          element: textareaRef.current!,
          previewRender: function (plainText: string, preview: HTMLElement) {
            // Async method
            const previewButton = (this as any).parent.toolbarElements['preview'] as HTMLButtonElement;
            const sideBySideButton = (this as any).parent.toolbarElements['side-by-side'] as HTMLButtonElement;
            const previewIsActive = previewButton.classList.contains('active') || sideBySideButton.classList.contains('active');

            if (!previewIsActive) {
              setActivePreview(false);
            }

            if (!previewIsActive) return '...';
            const prefix = itemPath.substring(0, itemPath.lastIndexOf("/"));
            if (!!customPreview) {
              customPreview(plainText).then(svg => {
                preview.innerHTML = svg;
                setActivePreview(true);
              })
            } else {
              preloadImages(bucket, prefix, plainText).then((updatedText) => {
                preview.innerHTML = (toHtmlConverter.parse(updatedText) as string);
                setActivePreview(true);
              });
            }
    
            // If you return null, the innerHTML of the preview will not
            // be overwritten. Useful if you control the preview node's content via
            // vdom diffing.
            // return null;
    
            return "Loading...";
          },
          initialValue,
          forceSync: true,
          autofocus: true,
          maxHeight: '70vh',
          autosave: {
            enabled: false,
            uniqueId: itemPath ?? 'md-editor-default',
            delay: 1000,
            submit_delay: 5000,
            timeFormat: {
              locale: 'en-US',
              format: {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
              },
            },
            text: "Autosaved: "
          },
          status: ["lines", "words", "cursor",
            {
              className: 'autosave',
              defaultValue: (element: HTMLElement) => autosaveStatus.current = element,
              onUpdate: (_: HTMLElement) => { },
            }
          ],
          toolbar: toolbar,
          renderingConfig: {
            codeSyntaxHighlighting: true,
          }
        });
        // easyMde.codemirror.
        setSimpleMde(easyMde);

        return () => {
          easyMde.toTextArea();
          easyMde.cleanup();
          setSimpleMde(undefined);
        }
      }
    }, [itemPath, toolbar, bucket, onToggleFullScreen, customPreview, EasyMDE]);

    useEffect(() => {
      if (!!simplemde) {
        const autosaveInterval = setInterval(autosaveHandler, 5000);
        loadStoredValueHandler();

        simplemde.codemirror.on('change', () => {
          onChange(/*simplemde.value()*/);
        });

        return () => {
          clearInterval(autosaveInterval);
        };
      }
    }, [simplemde, loadStoredValueHandler, autosaveHandler, onChange]);

    return (<div className="markdown-editor__container">
      {activePreview && <CopyCodePortal rootElement={textareaRef.current?.parentElement} />}
      <textarea ref={textareaRef} >
      </textarea>
    </div>);
  }
  return undefined;
}