import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { selectUser } from "redux/modules/user/selectors";
import { deleteUser } from "redux/modules/user/userSlice";
import { UserProfile } from "./user-profile";

export type UserProfileConnectedProps = object;

export function UserProfileConnected() {
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const deleteUserCbk = useCallback(() => dispatch(deleteUser()), [dispatch]);

  return (<UserProfile user={user} deleteUser={deleteUserCbk}/>);
}