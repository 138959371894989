import configureAws from "aws-configurator";
import { decodeJwt } from "./jwt";
import { clearLoginData, getLoginData, setLoginData } from "utils/oauth/login-data";
import { generateState } from "./state";
import { Subject } from "rxjs";
import { UserData } from "@model/user-state";
import { authDomain } from "aws-website-config";
import { getSession, removeSession, setSession } from "./session";

type AuthTokenPayload = {
  auth_time: number;
  client_id: string;
  "cognito:groups": string[];
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  origin_jti: string;
  scope: string;
  sub: string;
  token_use: string;
  username: string;
  version: number;
};

const config = configureAws();
export const stateKey = 'state';
export const codeVerifierKey = 'code_verifier';


const sessionItem = import.meta.env.SSR ? undefined : getSession();
const storedState = import.meta.env.SSR ? undefined : localStorage.getItem(stateKey);

const loginData = getLoginData();

export async function handleAuthFlow(authSubject: Subject<UserData>) {

  if (!import.meta.env.SSR) {
    if (await handleSignoutRedirect(authSubject)) return;

    if (await handleCurrentUser(authSubject)) return;

    if (await handleSigninCode(authSubject)) return;
  }
}

async function handleCurrentUser(authSubject: Subject<UserData>): Promise<boolean> {
  if (!sessionItem) return false;
  const session = sessionItem;
  return await completeAuthFlow(session, authSubject);
}

async function completeAuthFlow(session: any, authSubject: Subject<UserData>): Promise<boolean> {
  const jwt = decodeJwt(session.access_token) as AuthTokenPayload;
  const now = ~~(Date.now() / 1000);
  if (jwt.exp < now) {
    const tokenRefreshed = await refreshToken();
    if (!tokenRefreshed) {
      removeSession();
      return false;
    }
    return true;
  } else {
    const idToken = decodeJwt(session.id_token);
    setLoginData({
      [`cognito-idp.${config.aws_cognito_region}.amazonaws.com/${config.aws_user_pools_id}`]: session.id_token
    });

    authSubject.next({
      signInUserSession: {
        idToken,
        expires_in: session.expires_in
      }
    });

    return true;
  }
}

export async function refreshToken(): Promise<boolean> {
  const tokenEndpoint = `https://${authDomain}/oauth2/token`;

  const session = getSession();
  try {
    const params: Record<string, string> = {
      'client_id': config.aws_user_pools_web_client_id,
      'grant_type': 'refresh_token',
      'refresh_token': session.refresh_token
    };

    const response = await fetch(tokenEndpoint, {
      method: 'POST',
      body: Object.getOwnPropertyNames(params).map(x => `${encodeURIComponent(x)}=${encodeURIComponent(params[x])}`).join('&'),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });

    if (response.ok) {
      const refreshSession = await response.json(); // This contains access_token, id_token, and refresh_token
      if (!refreshSession.error) {
        const newSession = { ...session, ...refreshSession };
        setSession(newSession);

        setLoginData({
          [`cognito-idp.${config.aws_cognito_region}.amazonaws.com/${config.aws_user_pools_id}`]: newSession.id_token
        });
    
        return true;
      }

      return false;
    }
  } catch (error) {
    console.error('Token refresh Error:', error);
    throw error;
  }
  
  return false;
}

async function handleSigninCode(authSubject: Subject<UserData>): Promise<boolean> {
  if (!storedState) return false;

  const authenticationCodeUrl = window.location.href.startsWith(config.oauth.redirectSignIn);
  if (authenticationCodeUrl) {
    const params = new URL(window.location.href).searchParams;
    const code = params.get("code");
    const state = params.get("state");
    if (!!code && !!state) {
      if (state !== storedState) {
        console.error('Invalid auth flow state');
        return false;
      }
      const tokenEndpoint = `https://${authDomain}/oauth2/token`;

      try {
        const codeVerifier = localStorage.getItem(codeVerifierKey)?.toString();
        const params: Record<string, string> = {
          'code': code,
          'client_id': config.aws_user_pools_web_client_id,
          'grant_type': 'authorization_code',
          'redirect_uri': config.oauth.redirectSignIn,
          'code_verifier': codeVerifier!
        };

        const response = await fetch(tokenEndpoint, {
          method: 'POST',
          body: Object.getOwnPropertyNames(params).map(x => `${encodeURIComponent(x)}=${encodeURIComponent(params[x])}`).join('&'),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });

        if (response.ok) {
          const session = await response.json(); // This contains access_token, id_token, and refresh_token
          if (!session.error) {
            localStorage.removeItem(codeVerifierKey);
            localStorage.removeItem(stateKey);
            setSession(session);
            completeAuthFlow(session, authSubject);
          }

          return true;
        }
      } catch (error) {
        console.error('Error exchanging code for tokens:', error);
        throw error;
      }
    }
  }
  return false;
}

async function handleSignoutRedirect(authSubject: Subject<UserData>): Promise<boolean> {
  if (!loginData) return false;

  const isSignoutUrl = window.location.href.startsWith(config.oauth.redirectSignOut);
  if (isSignoutUrl) {
    removeSession();
    authSubject.next(undefined);
    clearLoginData();
    return true;
  }

  return false;
}

export function startSignInFlow(value: string, method: string, codeChallenge: string) {
  const state = generateState();
  localStorage.setItem(stateKey, state);
  localStorage.setItem(codeVerifierKey, value);
  const clientId = config.aws_user_pools_web_client_id;

  const params: Record<string, string> = {
    redirect_uri: config.oauth.redirectSignIn,
    response_type: 'code',
    client_id: clientId,
    identity_provider: 'COGNITO',
    scope: config.oauth.scope.join(' '),
    state: state,
    code_challenge: codeChallenge,
    code_challenge_method: method
  };


  const stringParams = Object.getOwnPropertyNames(params).map(x => `${encodeURIComponent(x)}=${encodeURIComponent(params[x])}`).join('&');
  const loginUrl = `https://${authDomain}/oauth2/authorize?${stringParams}`;

  // Redirect to the login URL
  window.location.href = loginUrl;  
}
