import * as toolkit from '@reduxjs/toolkit';
import { DrawerState, UiState } from '@model/ui-state';
import { drawerWidth } from 'global-settings';

export const initialState: UiState = {
  drawer: undefined,
  fullScreen: false,
  compactLayout: false
};

export const initDrawer: DrawerState = {
  open: false,
  width: drawerWidth
}

export type MountDrawerPayload = {
  open: boolean,
  width?: number
}

const uiStateSlice = toolkit.createSlice({
  name: 'ui-state',
  initialState,
  reducers: {
    mountDrawer: (state: UiState, action: toolkit.PayloadAction<MountDrawerPayload | undefined>) => {
      return {...state, drawer: {...(state.drawer ?? initDrawer), width: action.payload?.width ?? drawerWidth, open: action.payload?.open === true}};
    },
    openDrawer: (state: UiState) => {
      return {...state, drawer: {...(state.drawer ?? initDrawer), width: state.drawer?.width ?? drawerWidth, open: true}};
    },
    closeDrawer: (state: UiState) => {
      return {...state, drawer: {...(state.drawer ?? initDrawer), width: state.drawer?.width ?? drawerWidth, open: false}};
    },
    unmountDrawer: (state: UiState) => {
      return {...state, drawer: undefined};
    },
    fullScreen: (state: UiState, { payload }: toolkit.PayloadAction<boolean>) => {
      return {...state, fullScreen: payload }
    },
    setCompactLayout: (state: UiState, { payload }: toolkit.PayloadAction<boolean>) => {
      return { ...state, compactLayout: payload };
    }
  }
})

export const { mountDrawer, unmountDrawer, openDrawer, closeDrawer, fullScreen, setCompactLayout } = uiStateSlice.actions;
export default uiStateSlice.reducer as toolkit.Reducer<UiState>;