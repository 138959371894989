import { OpenGraphAttributes } from "@model/open-graph";
import { useEffect, useId, useMemo } from "react"
import { createPortal } from "react-dom"

export type DocumentMetaProps = {
  og?: OpenGraphAttributes,
  title?: string,
  description?: string
}

const titles: string[] = [];

export function DocumentMeta({ og, title, description }: DocumentMetaProps) {
  if (!!import.meta.env.SSR) return null;

  const id = useId();
  const metaTags = useMemo(() => Array.from(document.head.getElementsByTagName('meta')), []);
  const titleElement = useMemo(() => {
    if (!!title) {
      const te = document.head.getElementsByTagName('title')[0];
      if (!!te) return te;

      const newTitleElement = document.createElement('title');
      document.head.appendChild(newTitleElement);

      return newTitleElement;
    }
  }, [title]);

  useEffect(() => {
    if (!!title && !!titleElement) {
      const currentTitle = titleElement.innerText;      
      if (!!currentTitle) titles.push(currentTitle);
      titleElement.innerText = title;
      return () => {
        const prevTitle = titles.pop();
        if (!!prevTitle) {
          titleElement.innerText = prevTitle;
        } else {
          titleElement.remove();
        }
      }
    }
  }, [title, titleElement]);

  const renderDescription = useMemo(() => !!description && !!metaTags.find(x => x.getAttribute('name') === 'description'), [description, metaTags]);

  const ogTags = useMemo(() => !!og && Object.getOwnPropertyNames(og).filter(propName => metaTags.find(mt => mt.getAttribute('property') === `og:${propName}`))
    .map(propName => <meta key={propName} property={`og:${propName}`} content={og[propName]} />), [metaTags, og]);

  return createPortal(<>
    {renderDescription && <meta
      name="description"
      content={description}
    />}
    {!!og && <>
      {ogTags}
    </>}
  </>, document.head, id);
}