import { UserState } from '@model/user-state';
import * as toolkit from '@reduxjs/toolkit';
import { UserData } from '@model/user-state';

export const initialState: UserState = {
  displayName: 'Guest',
  authenticated: false,
  userData: undefined,
  isBlogger: false
};

export const userSlice = toolkit.createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (_: UserState, action: toolkit.PayloadAction<UserData>) => {
      if (!!action.payload) {
        return { 
          displayName: action.payload.signInUserSession?.idToken.name,
          authenticated: true,
          userData: action.payload,
          isBlogger: action.payload.signInUserSession?.idToken["cognito:groups"].includes('Bloggers') ?? false
        } as UserState;
      } else {
        return initialState;
      }
    },
    signin: _ => _,
    signout: _ => _,
    deleteUser: _ => _
  }
})

export const { setUser, signin, signout, deleteUser } = userSlice.actions;
export default userSlice.reducer as toolkit.Reducer<UserState>;