import { PropsWithChildren, StrictMode } from 'react';

import './index.scss';
import { Provider } from 'react-redux'
import { StoreType } from './redux/store';
import { ThemeProvider } from '@mui/material';
import { mainTheme } from 'main-theme';

export type CommonAppProps = PropsWithChildren & {
  store: StoreType
}

export function CommonApp({ children, store }: CommonAppProps) {
  return (<ThemeProvider theme={mainTheme}>
    <Provider store={store}>
      <StrictMode>
        {children}
      </StrictMode>
    </Provider>
  </ThemeProvider>
  );
}