import { DependencyList, EffectCallback, useEffect, useMemo } from "react";
import { Subject, debounceTime, tap, withLatestFrom } from "rxjs";

declare const UNDEFINED_VOID_ONLY: unique symbol;
// Destructors are only allowed to return void.
type Destructor = () => void | { [UNDEFINED_VOID_ONLY]: never };

export function useDebounceEffect(callback: EffectCallback, deps?: DependencyList, dueTime?: number) {
    const callbacks$ = useMemo(() => new Subject<EffectCallback>(), []);
    const destructors$ = useMemo(() => new Subject<void | Destructor>(), []);
    useEffect(() => { 
        const sub = callbacks$.asObservable().pipe(
            withLatestFrom(destructors$),
            debounceTime(dueTime ?? 100),
            tap(([cbk, dst]) => {
                if (!!dst) dst();
                const destructor = cbk();
                destructors$.next(destructor as Destructor);
            })
        )
        .subscribe();
        destructors$.next();
        return () => sub.unsubscribe();
    }, [callbacks$, destructors$, dueTime]);
    useEffect(() => {
        callbacks$.next(callback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...(deps ?? []), callbacks$]);
}