import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { selectBlogger } from 'redux/modules/user/selectors';
import { BlogCreator, CreateBlogFunc } from './blog-creator';
import { createBlog } from "redux/modules/user/blogger/bloggerSlice";

function ConnectedBlogCreator() {
  const blogger = useAppSelector( selectBlogger );
  const dispatch = useAppDispatch();
  const createBlogCallback = useCallback<CreateBlogFunc>((blogInfo, parentId, image) => dispatch(createBlog({
    blogInfo,
    parent: parentId,
    image
  })), [dispatch]);

  return (
    <BlogCreator blogger={blogger} 
      createBlog={createBlogCallback}/>
  );
}

export default ConnectedBlogCreator;
