import './blogs.scss';
import { useCallback } from 'react';
import { BloggerState } from '@model/blogger-state';
import List from '@mui/material/List';
import { BlogTreeItem } from './blog-tree-node';
import { BlogTreeNode } from '@model/blog-tree-node';
import { useNavigate } from 'react-router-dom';
import { FileManager } from 'utils/file-manager';
import { ExecuteBatchActionTypes } from 'redux/modules/user/blogger/bloggerSlice';
import { BlogsEditorPath, BlogsReviewerPath } from 'app-constants';

export type BlogsListProps = {
  blogger: BloggerState,
  toggleBlogNode: (node: BlogTreeNode, parent?: BlogTreeNode) => void,
  checkBlogNodes?: (item: BlogTreeNode, select: boolean) => void,
  executeBatchAction: (action: ExecuteBatchActionTypes) => void,
  clearCheckedBlogNodes: () => void,
  onNavigate?: (navPath: string) => void
}

function BlogsList({blogger, toggleBlogNode, checkBlogNodes, executeBatchAction, onNavigate, clearCheckedBlogNodes}: BlogsListProps) {
  const navigate = useNavigate();
  

  const handleBlogClick = useCallback((item: BlogTreeNode, parent?: BlogTreeNode) => {
    const blogId = parent?.prefix.replace(blogger.blogsLocation, '');
    const itemId = encodeURIComponent(item?.name ?? 'no-item-name')

    if (item?.leastNode === false) {
      toggleBlogNode(item, parent);
    } else {
      clearCheckedBlogNodes();
      if (checkBlogNodes) checkBlogNodes(item, true);
      const editorType = FileManager.getEditorType(item!.name);
      if (editorType) {
        const imageExt = FileManager.getImageExtension(editorType) ?? '';
        const navPath = blogger.mode === 'editor'
          ? `/${BlogsEditorPath}/edit/${editorType}/${blogId ?? ''}${itemId}`
          : `/${BlogsReviewerPath}/view/${editorType}/${blogId ?? ''}${itemId}${imageExt}`;
        navigate(navPath);
        if (onNavigate) onNavigate(navPath);
      }
    }
  }, [blogger?.blogsLocation, navigate, onNavigate, toggleBlogNode, blogger?.mode]);

  return (    
      <List
      sx={{ width: '100%', maxWidth: '100%', bgcolor: 'background.paper', paddingLeft: '8px' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      { blogger?.blogs.map((blog, index) => {
        return (<BlogTreeItem key={blog?.name ?? index} mode={blogger?.mode} item={blog} itemClicked={handleBlogClick} selectItems={checkBlogNodes} executeBatchAction={executeBatchAction} onNavigate={onNavigate} clearSelectedItems={clearCheckedBlogNodes} />)       
      })}
    </List>
  );
}

export default BlogsList;