export function prefixToBlogId(prefix?: string) {
  return !!prefix 
        ? prefix.substring(0, prefix.length - (
          prefix[prefix.length - 1] === '/' ? 1 : 0
        ))
        : 'no-parent';
}

export function getParentLevelFromId(prefix?: string): number {
  if (!prefix) return -1;

  const parts = prefix.split('/').filter(x => x != '');
  return parts.length - 1;
}

export function encodeMetaInfoPath(path: string) {
  return path.split('/').map(x => encodeURIComponent(x)).join('/');
}