import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { createS3Client } from "./s3-client.factory";

export async function preloadImages(bucket: string, prefix: string, mdText: string) {
  const client = createS3Client();

  return await replaceImageUrlsAsync(mdText, (url) => {
    const cmd = new GetObjectCommand({
      Bucket: bucket,
      Key: `${prefix}/${url}`
    });

    return getSignedUrl(client, cmd);
  });
}

export function replaceImageUrlsAsync(mdText: string, replaceUrl: (url: string) => Promise<string>): Promise<string> {
  const res = replaceImageUrls(mdText, replaceUrl);
  return (typeof res === 'string') ? new Promise((resolve) => { resolve(res) }) : res;
}

export function replaceImageUrls(mdText: string, replaceUrl: (url: string) => string | Promise<string>): ReturnType<typeof replaceUrl> {
  const upTxt: string[] = [];
  const regex = /!\[(.*?)\]\((.*?)\)/g;
  let match: RegExpExecArray | null = null;
  let last = 0;
  const signedUrlPromises: ({ replacedUrl: Promise<string>, alt: string } | null) [] = [];

  while ((match = regex.exec(mdText)) !== null) {
    const [_, alt, url] = match;
    const isAbsoluteUrl = (!!url && url.length >= 7 && (url[4] === ':' || (url[5] === ':' && url[4] === 's')) && url.startsWith('http'));
    if (isAbsoluteUrl) continue;


    const replacedUrl = replaceUrl(url);
    if (replacedUrl instanceof Promise) {
      upTxt.push(mdText.substring(last, match.index));
      signedUrlPromises.push({ replacedUrl, alt });
    }
    else {      
      upTxt.push(mdText.substring(last, match.index) + `![${alt}](${replacedUrl})`);
    }
    last = regex.lastIndex;
  }

  if (upTxt.length > 0 && last < mdText.length) {
    upTxt.push(mdText.substring(last, mdText.length));
  }

  if (signedUrlPromises.length > 0) {
    return Promise.all(signedUrlPromises.map(x => x?.replacedUrl)).then((urls) => {
      for (let i = urls.length - 1; i >= 0; i--) {
        const url = urls[i];
        if (!!url) {
          const { alt } = signedUrlPromises[i]!;
          upTxt.splice(i + 1, 0, `![${alt}](${url})`);
        }
      }
      return upTxt.length > 0 ? upTxt.join('') : mdText; 
    }) as Promise<string>;
  }
  return (upTxt.length > 0 ? upTxt.join('') : mdText) as string;
}
