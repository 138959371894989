const sessionKey = 'session';

export function getSession() {
  const session = JSON.parse(localStorage.getItem(sessionKey)!);
  return session;
}

export function removeSession() {
  localStorage.removeItem(sessionKey);
}

export function setSession(newSession: object) {
  localStorage.setItem(sessionKey, JSON.stringify(newSession));
}