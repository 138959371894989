import {
  Navigate,
  Outlet,
} from "react-router-dom";

import { StoreType } from '../redux/store';
import ConnectedApp from 'connectedApp';
import ConnectedBlogs from 'components/blogs.connected';
import { loadMainPage } from 'redux/modules/app/appStateSlice';
import ConnectedHomePage from 'components/home-page.connected';
import { BlogsEditorPath, BlogsReviewerPath } from 'app-constants';
import { setEditorMode, setReviewerMode, toggleBlogNode } from 'redux/modules/user/blogger/bloggerSlice';

import { ConnectedPostViewer } from 'components/post-viewer.connected';
import { PostViewer } from 'components/post-viewer';

import { editorRoutes } from "routes/editor-routes";
import { reviewerRoutes } from "routes/reviewer-routes";
import { ErrorBoundary } from "components/error-boundary";
import { UserProfileConnected } from "components/user-profile.connected";

let allRoutes: any = undefined;
export const getAllRoutes = (store: StoreType) => {
  if (!!allRoutes) return allRoutes;
  allRoutes = [
    {
      element: <ConnectedApp />,
      children: [
        ...(import.meta.env.SSR ? [] :
          [{
            path: "/signed",
            element: <Navigate to="/" replace={true} />,
          },
          {
            path: "/sidnedout",
            element: <Navigate to="/" replace={true} />,
          },
          {
            path: `/${BlogsEditorPath}`,
            element: <ConnectedBlogs />,
            children: editorRoutes,
            loader: async () => {
              store.dispatch(setEditorMode());
              store.dispatch(toggleBlogNode({ blogItem: null, itemParent: null }));
              return new Response("{}", {
                status: 200,
                headers: {
                  "Content-Type": "application/json; utf-8",
                },
              });
            }
          },
          {
            path: `/${BlogsReviewerPath}`,
            element: <ConnectedBlogs />,
            children: reviewerRoutes,
            loader: async () => {
              store.dispatch(setReviewerMode());
              store.dispatch(toggleBlogNode({ blogItem: null, itemParent: null }));
              return new Response("{}", {
                status: 200,
                headers: {
                  "Content-Type": "application/json; utf-8",
                },
              });
            }
            }]),
        {
          path: 'user/profile',
          element: <UserProfileConnected />
        },
        {
          // index: true,
          element: <Outlet />,
          path: '/:page?',
          loader: async ({ params }: { params: any }) => {
            store.dispatch(loadMainPage({ page: params.page }));
            return new Response('{}', {
              status: 200,
              headers: {
                "Content-Type": "application/json; utf-8",
              },
            });
          },
          children: [
            {
              path: '*',
              element: <ConnectedPostViewer>{props => <ErrorBoundary fallback={<span>Post Viewer rendering error :(</span>}><PostViewer {...props} /></ErrorBoundary>}</ConnectedPostViewer>,
              loader: async () => {
                // store.dispatch(loadMainPage({ page: params.page }));
                return new Response('{}', {
                  status: 200,
                  headers: {
                    "Content-Type": "application/json; utf-8",
                  },
                });
              },
            },
            {
              index: true,
              element: <ConnectedHomePage />
            }
          ]
        },
      ],
    }
  ];
  return allRoutes;
}
